const auditReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "setAudit":
      return payload;
      case "audit/SET_FILE_EXPIRED":
        return payload;
    default:
      return state;
  }
};

export default auditReducer;
