import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../env";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TopNavigation from "../components/TopNavigation";
import Paper from "@mui/material/Paper";
import * as moment from "moment";

export default function Search() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const [codeAvocat, setCodeAvocat] = useState();
  const [loading, setLoading]=useState(false);
  const [response, setResponse]=useState(false)
  const [lawyer, setLawyer]=useState()
  const [sales, setSales]=useState([])
  const [processedFiles, setProcessedFiles]=useState([])
  const [pendingFiles, setPendingFiles]=useState([])

  const [totalPendingFiles, setTotalPendingFiles]=useState([])
  const [totalProcessedFiles, setTotalProcessedFiles]=useState()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [total_amount_sales, setTotal_amount_sales] = useState(0);

  function submit() {
    setLoading(true)
    axios.get(endpoint + "/insight/audit_operation/" + codeAvocat, {
        headers: { "x-token": data.user.token },
      })
      .then(function (response) {
        setLoading(false)
        dispatch({ type: "search/SET_RESULT", payload: response.data.payload });
        setLawyer(response.data.payload.lawyer)

        let audit=response.data.payload.audit
        let sales = response.data.payload.sales;
        let total_amount = 0;
        let tempProcessedFiles=[]
        let tempPendingFiles=[]
        let tempTotalProcessedFiles=0
        let tempTotalPendingFiles=0
        
        if(audit){
          audit.operation.forEach((element) => {
            if (element.state!=="pending") {
             tempProcessedFiles.push(element)
             tempTotalProcessedFiles=element.amount+tempTotalProcessedFiles
            }
            else {
             tempPendingFiles.push(element)
             tempTotalPendingFiles=element.amount+tempTotalPendingFiles
            }
         });
        }
        if(sales){
          sales.forEach((element) => {
            total_amount = total_amount + element.amount;
        });
        }
        setProcessedFiles(tempProcessedFiles)
        setPendingFiles(tempPendingFiles)
        setTotalProcessedFiles(tempTotalProcessedFiles)
        setTotalPendingFiles(tempTotalPendingFiles)
        setSales(sales)
        setTotal_amount_sales(total_amount);
        setLoading(false)
        setResponse(true)
      })
      .catch((error) => {
        setLawyer({})
        setProcessedFiles([])
        setPendingFiles([])
        setTotalProcessedFiles({})
        setTotalPendingFiles({})
        setSales([])
        setTotal_amount_sales({});
        setLoading(false)
        setResponse(false)
        return console.log("error");
      });
  }

  function ElementHeader() {
    return (
      <div className="flex justify-items-start pt-2">
        <div className="ml-4 w-20">مد ينة</div>
        <div className="ml-4 w-48">محكمة</div>
        <div className="ml-4 w-32">مرجع الملف</div>
        <div className="ml-4 w-20">القضية</div>
        <div className="ml-4 w-20">الدمغة</div>
        <div className="ml-4 w-20">المبلغ</div>
        <div className="ml-4 w-40">تاريخ العملية</div>
        <div className="ml-4 w-40">آخر اجل الأداء</div>
        <div className="ml-4 w-40">حالة الملف</div>
      </div>
    );
  }

  function ElementCard(props) {
    return props.operation.map((element) => {
      return (
        <div className="flex justify-items-start pt-2">
          <div className="ml-4 w-20">
            {element.city}
          </div>
          <div className="ml-4 w-48">{element.court}</div>
          <div className="ml-4 w-32">
            {element.file.number}/{element.file.code}/{element.file.year}
          </div>
          <div className="ml-4 w-20">
            {element.case_type ? element.case_type.name : ""}
          </div>
          <div className="ml-4 w-20">{element.stamp.name}</div>
          <div className="ml-4 w-20">{element.amount} درهم</div>
          <div className="ml-4 w-40">
          {moment(element.created_at.toString()).format("DD/MM/YY")}
          </div>
          <div className="ml-4 w-40">
          {moment(element.dueDate_at.toString()).format("DD/MM/YY")}
          </div>
          {element.state==="pending" && <div className="ml-4 w-40">
          {moment(element.dueDate_at).diff(moment(Date.now()),"days")>=0 ?<div className="ml-4 w-40 text-gray-400">إنتظار التصحيح</div>:<div className="ml-4 w-40 text-red-400">إحالة على الإقتطاع</div>}
          </div>}
          {element.state=="paid_original" && <div className="ml-4 w-20 text-blue-600">تم الأداء</div>}
          {element.state=="mistake" && <div className="ml-4 w-20 text-green-600">تم التصحيح</div>}
        </div>
      );
    });
  }

  function Card(props) {
    return (
      <div className="bg-gray-200 p-4 overflow-auto  text-black mb-12 font-semibold space-y-2">
        <ElementHeader />
        <div className="border-b-2 border-black" />
        <ElementCard operation={props.pendingFiles} />
      </div>
    );
  }

  useEffect(() => {
    document.documentElement.dir = "rtl";
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    if (!data) {
      return navigate("/login");
    }

    if (!mystate.user._id) {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (!data) {
        return navigate("/login");
      }
    }
    window.scrollTo(0, 0);
  }, [mystate.user._id]);

  return (
    <div className="container mx-auto mt-8 noselect font-cairo mb-12">
      <TopNavigation user={data.user} />
      <div className="text-2xl mb-12 font-semibold mt-16">محرك بحث المحامي</div>

      <div className="flex space-x-2 justify-end flex-row-reverse">
        <input
          type="text"
          className="bg-mygray border-2 border-gray-200 rounded-md h-12 text-center text-lg w-[200pxa]"
          onChange={(e) => setCodeAvocat(e.target.value)}
          value={codeAvocat}
        />
        {!loading && codeAvocat &&
            <div
            className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center rounded-md cursor-pointer hover:bg-blue-400"
            onClick={() => submit()}
            >
            بحث
            </div>
        }
        {loading &&
            <div className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center rounded-md cursor-wait">
            جاري البحث
            </div>
        }
        {!codeAvocat &&
            <div className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center cursor-not-allowed opacity-60 rounded-md">
            بحث 
            </div>
        }
      </div>
      <div className="border-b-2 border-gray-200 mt-12 w-full" />

      {!loading && response && lawyer &&
        <idv>
           <div className="text-2xl mb-12 font-semibold mt-16">
              {lawyer.name}
            </div>
          {pendingFiles && (
            <div>
              <div className="bg-red-400 lg:w-[400px] mt-12 p-4  text-white mb-12 font-semibold space-y-2">
                <div className="flex justify-items-start text-xl mb-8">
                  <div className="w-[200px]">ملفات بدون دمغة</div>
                </div>
                <div className="flex justify-items-start text-xl mb-6">
                  <div className="w-[200px]">المجموع الاجمالي</div>
                  <div>{totalPendingFiles} درهم</div>
                </div>
                <div className="flex justify-items-start  text-xl">
                  <div className="w-[200px]">عدد الملفات</div>
                  <div className="w-[40px]">
                    {pendingFiles.length}
                  </div>
                </div>
              </div>
              <Card pendingFiles={pendingFiles}/>
            </div>
          )}
          {processedFiles && (
            <div>
              <div className="bg-myblue lg:w-[400px] mt-12 p-4  text-white mb-12 font-semibold space-y-2">
                <div className="flex justify-items-start text-xl mb-8">
                <div className="w-[200px]"> الملفات بعد التسوية</div>
                </div>
                <div className="flex justify-items-start text-xl mb-6">
                  <div className="w-[200px]">المجموع الاجمالي</div>
                  <div>{totalProcessedFiles} درهم</div>
                </div>
                <div className="flex justify-items-start  text-xl">
                  <div className="w-[200px]">عدد الملفات</div>
                  <div className="w-[40px]">
                    {processedFiles.length}
                  </div>
                </div>
              </div>
              <Card pendingFiles={processedFiles}/>
            </div>
          )}
          {sales && data.user.is_admin && (
            <div>
              <div className="bg-mygreen lg:w-[400px] mt-12 p-4  text-white mb-12 font-semibold space-y-2">
                <div className="flex justify-items-start text-xl mb-8">
                  <div className="w-[200px]">عمليات الاقتناء</div>
                </div>
                <div className="flex justify-items-start text-xl mb-6">
                  <div className="w-[200px]">المجموع الاجمالي</div>
                  <div>{total_amount_sales} درهم</div>
                </div>
                <div className="flex justify-items-start  text-xl">
                  <div className="w-[200px]">العدد</div>
                  <div className="w-[40px]">{sales.length}</div>
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">
                        <div className="font-bold font-cairo">فئة الدمغة</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-bold font-cairo">وحدات</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-bold font-cairo">
                          الرقم التسلسلي للدمغة
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-bold font-cairo">طريقة الاداء</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-bold font-cairo">تاريخ العملية</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-bold font-cairo">مد ينة</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-bold font-cairo">نقطة البيع</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-bold font-cairo">المبلغ الإجمالي</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {sales.length && (
                    <TableBody>
                      {sales.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell align="right">
                            <div
                              className={
                                row.item.price == "500"
                                  ? "font-cairo font-semibold text-orange-600"
                                  : "font-cairo font-semibold text-blue-600"
                              }
                            >
                              ({row.item.price})
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="font-cairo">{row.quantity}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div
                              className={
                                row.item.price == "500"
                                  ? "font-cairo font-semibold text-orange-600"
                                  : "font-cairo font-semibold text-blue-600"
                              }
                            >
                              {row.stamp_serie.from} - {row.stamp_serie.to}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="font-cairo">
                              {row.payment.p_type === "check" ? "شيك" : "نقد"}
                            </div>
                            <div className="">{row.payment.p_code}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="font-cairo">
                              {moment(row.created_at.toString()).format("L")}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="font-cairo">
                            {row.city}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="font-cairo">
                              {row.zone && row.zone.toUpperCase()}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="font-cairo bg-mygreen py-1 px-2 font-bold text-white">
                              {row.amount} درهم
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          )}
        </idv>
      }
    </div>
  );
}
