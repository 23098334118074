const user = {
  key: "32154",
  username: "annouri",
  email: "annouri.ilias@gmail.com",
  city: "Vally",
  password: "Vally",
};

const userReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "setUser":
      return payload;
    default:
      return state;
  }
};

export default userReducer;
