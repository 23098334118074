import { createStore } from "redux";
import { combineReducers } from "redux";

import userReducer from "./reducers/userReducer";
import saleReducer from "./reducers/saleReducer";
import auditReducer from "./reducers/auditReducer";
import searchReducer from "./reducers/searchReducer";
import lawyerReducer from "./reducers/lawyerReducer";

const reducers = combineReducers({
  user: userReducer,
  sale: saleReducer,
  audit: auditReducer,
  search: searchReducer,
  lawyer: lawyerReducer,
});

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
