import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function TopBar(props) {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between">
      <Helmet>
        <title>مشروع الدمغة</title>
      </Helmet>
      <div>{props.lawyer.name}</div>
      <div onClick={() => navigate("/home_lawyer")} className="cursor-pointer">
        عودة
      </div>
    </div>
  );
}
