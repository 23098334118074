import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TopNavigation from "../components/TopNavigation";
import { endpoint } from "../env";

import logo from "../logo.svg";

export default function Audit() {
  let words = {
    office: {
      ar: "المكتب",
      fr: "Bureau",
    },
    fileInfo: { ar: "مرجع الملف", fr: "Document" },
    court: {
      ar: "المحكمة",
      fr: "Tribunal",
    },
    timbre: {
      ar: "فئة الدمغة",
      fr: "Timbre",
    },
    save: {
      ar: "حفظ",
      fr: "Enregistrer",
    },
    cancel: {
      ar: "إلغاء",
      fr: "Annuler",
    },
  };
  let language = "fr";
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const [operationDone, setOperationDone] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [option, SetOption] = useState({
    option_a: false,
    option_b: false,
    option_c: false,
  });

  const [case_type,setCase_type]= useState({
    type_a: false,
    type_b: false,
    type_c: false,
  });
  const [case_type_selected,setCase_type_selected]=useState({code:"", name:""})
  const [stamp, setStamp] = useState({ code: "", name: "" });
  const [codeAvocat, setCodeAvocat] = useState("");
  const [selectedCourt, setSelectedCourt] = useState("اختر المحكمة");
  const [courtList, setCourtList] = useState([
    "إستئنافية الناظور",
    "ابتدائية الناظور",
    "اسرة",
    "إستئنافية الحسييمة",
    "ابتدائية الحسييمة",
    "ابتدائية الدريوش",
    "التجارية",
    "الإدارية",
  ]);
  const [year, setYear] = useState("");
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");

  const [is_listopen, setIs_listopen] = useState(false);

  function CourtList() {
    return courtList.map((element) => (
      <div
        className={
          element === selectedCourt
            ? "mb-1 cursor-pointer bg-indigo-300 hover:bg-blue-500 hover:text-white rounded-sm px-2"
            : "mb-1 cursor-pointer  hover:bg-blue-500 hover:text-white rounded-sm px-2"
        }
        onClick={() => {
          setSelectedCourt(element);
          setIs_listopen(!is_listopen);
        }}
      >
        {element}
      </div>
    ));
  }

  const [dataResponse, setDataResponse] = useState({});
  function resetValue() {
    setStamp({ code: 1, name: "50 عادية" });
    setCodeAvocat("");
    SetOption({
      option_a: true,
      option_b: false,
      option_c: false,
    });
    setSelectedCourt("اختر المحكمة");
    setYear("");
    setCode("");
    setNumber("");
  }
  function logout() {
    localStorage.removeItem("damghaState");
    return navigate("/login");
  }
  function submit() {
    if(case_type.name==="" || stamp.name==="" || codeAvocat==="" || year==="" || code==="" || number==="")
    return console.log("Please make sure to add all informations")
    
    let amount = stamp.code == 1 ? 50 : 500;
    let dataSubmit = {
      codeAvocat: codeAvocat,
      court: selectedCourt,
      file: {
        year: year,
        code: code,
        number: number,
      },
      stamp_name: stamp.name,
      stamp_code: stamp.code,
      case_type:{
        code:case_type_selected.code,
        name:case_type_selected.name
      },
      amount: amount,
    };
    axios.post(endpoint + "/user/audit/" + codeAvocat, dataSubmit, {headers: { "x-token": data.user.token },}).then(function (response)
    {
    setDataResponse(response.data.payload);
    resetValue();
    setOperationDone(true);
    }).catch((error) => {
      return console.log(error);
    });
   
  }

  useEffect(() => {
    document.documentElement.dir = "rtl";
    if (mystate.user._id) {
      const localstorage = localStorage.getItem("damghaState");

      const data = JSON.parse(localstorage);
      if (!data) {
        return navigate("/login");
      }
      axios
        .get(endpoint + "/user", {
          headers: { "x-token": data.user.token },
        })
        .then(function (response) {
          dispatch({ type: "setUser", payload: response.data.payload.user });
        })
        .catch((error) => {
          return console.log("error");
        });
    } else if (!mystate.user._id) {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (!data) {
        return navigate("/login");
      }
      axios
        .get(endpoint + "/user", {
          headers: { "x-token": data.user.token },
        })
        .then(function (response) {
          dispatch({ type: "setUser", payload: response.data.payload.user });
        })
        .catch((error) => {
          return console.log(error);
        });
    }
    window.scrollTo(0, 0);
  }, [mystate.user._id]);

  return (
    <div className="flex flex-col  container lg:mx-auto mt-8   lg:w-[800px] px-4 font-cairo  rtl">
      <TopNavigation user={data.user} />
      {!operationDone && (
        <div className="flex flex-col space-y-6 mt-20 noselect">
          <div className="space-y-4">
            <div className="text-lg font-cairo">المكتب</div>
            <input
              type="text"
              className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 "
              onChange={(e) => setCodeAvocat(e.target.value)}
              value={codeAvocat}
            />
          </div>
          <div className="relative">
            <div className="text-lg font-cairo mb-4">المحكمة</div>
            <div
              onClick={() => setIs_listopen(!is_listopen)}
              type="text"
              className="bg-white border-2 border-gray-200  rounded-md h-12 flex px-2 text-center items-center text-lg w-60 cursor-pointer"
            >
              {selectedCourt}
            </div>
            {is_listopen && (
              <div className="bg-mygray border-2 border-gray-200 shadow-md w-60 py-2 px-2 rounded-lg absolute">
                <CourtList />
              </div>
            )}
          </div>
          <div className="space-y-4">
            <div className="text-lg font-cairo">مرجع الملف</div>
            <div className="flex justify-between w-[340px] mt-2">
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-20 "
                onChange={(e) => setYear(e.target.value)}
                value={year}
                placeholder="السنة"
              />
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-36 "
                onChange={(e) => setCode(e.target.value)}
                value={code}
                placeholder="الرمز" 
              />
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-20 "
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                placeholder="الرقم"
              />
            </div>
          </div>
          <div className="font-cairo space-y-4">
            <div className="text-lg">نوع القضية</div>
            <div className="flex justify-between lg:w-[360px] flex-row-reverse">
              <div
                type="text"
                className={`rtl border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ${
                  case_type.type_a ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  setCase_type({
                    type_a: true,
                    type_b: false,
                    type_c: false,
                  });
                  setCase_type_selected({ code: 1, name: "شكاية" });
                }}
              >
                 شكاية
              </div>
              <div
                type="text"
                className={`border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ${
                  case_type.type_b ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  setCase_type({
                    type_a: false,
                    type_b: true,
                    type_c: false,
                  });
                  setCase_type_selected({ code: 2, name: "مدني" });
                }}
              >
                مدني
              </div>
              <div
                type="text"
                className={`border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer  ${
                  case_type.type_c ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  setCase_type({
                    type_a: false,
                    type_b: false,
                    type_c: true,
                  });
                  setCase_type_selected({ code: 3, name: "جنحي" });
                }}
              >
                جنحي
              </div>
            </div>
            
          </div>

          <div className="font-cairo space-y-4">
            <div className="text-lg">فئة الدمغة</div>
            <div className="flex justify-between lg:w-[360px] flex-row-reverse">
              <div
                type="text"
                className={`rtl border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ${
                  option.option_a ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  SetOption({
                    option_a: true,
                    option_b: false,
                    option_c: false,
                  });
                  setStamp({ code: 1, name: "50 عادية" });
                }}
              >
                50 عادية
              </div>
              <div
                type="text"
                className={`border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ${
                  option.option_b ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  SetOption({
                    option_a: false,
                    option_b: true,
                    option_c: false,
                  });
                  setStamp({ code: 2, name: "تذييل" });
                }}
              >
                تذييل
              </div>
              <div
                type="text"
                className={`border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer  ${
                  option.option_c ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  SetOption({
                    option_a: false,
                    option_b: false,
                    option_c: true,
                  });
                  setStamp({ code: 3, name: "تلبس" });
                }}
              >
                تلبس
              </div>
            </div>
          </div>

          <div className="pt-8 flex lg:space-x-8 space-x-2 justify-end flex-row-reverse">
            <div
              onClick={() => navigate("/")}
              className="bg-gray-800 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
            >
              إلغاء
            </div>
            <div
              className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
              onClick={() => submit()}
            >
              حفظ
            </div>
          </div>
        </div>
      )}
      {operationDone && (
        <div className="mt-20 ">
          <div className="text-xl">تمت عملية التسجيل بنجاح</div>
          <div className="flex flex-col mt-8 space-y-4 text-lg bg-green-400 py-8 px-4 rounded-lg mb-4 lg:px-12">
            <div className="flex justify-between">
              <div classname="font-semibold">المكتب</div>
              <div classname="font-semibold">{dataResponse.lawyer.name}</div>
            </div>
            <div className="flex justify-between">
              <div classname=" font-semibold">المحكمة</div>
              <div classname=" font-semibold">{dataResponse.audit.court}</div>
            </div>
            <div className="flex justify-between">
              <div classname=" font-semibold">مرجع الملف</div>
              <div classname=" font-semibold">
                {dataResponse.audit.file.number}/{dataResponse.audit.file.code}/
                {dataResponse.audit.file.year}
              </div>
            </div>
            <div className="flex justify-between">
              <div classname=" font-semibold">نوع القضية</div>
              <div classname=" font-semibold">
              {dataResponse.audit.case_type.name}
              </div>
            </div>
            <div className="flex justify-between">
              <div classname=" font-semibold">فئة الدمغة</div>
              <div classname=" font-semibold">
                {dataResponse.audit.stamp.name}
              </div>
            </div>
          </div>
          <div
            className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
            onClick={() => {
              setOperationDone(false);
              navigate("/audit");
            }}
          >
            تابع ايضا
          </div>
        </div>
      )}
    </div>
  );
}
