import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../../env";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TopBar from "./TopBar";
import TopNavigation from "./TopNavigation";
import * as moment from "moment";

export default function SalesLawyer() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const [codeAvocat, setCodeAvocat] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [total_amount_sales, setTotal_amount_sales] = useState(0);

  function ElementHeader() {
    return (
      <div className="flex justify-items-start pt-2">
        <div className="ml-4 w-20">مدينة</div>
        <div className="ml-4 w-48">محكمة</div>
        <div className="ml-4 w-32">مرجع الملف</div>
        <div className="ml-4 w-20">القضية</div>
        <div className="ml-4 w-20">الدمغة</div>
        <div className="ml-4 w-20">المبلغ</div>
        <div className="ml-4 w-40">تاريخ العملية</div>
      </div>
    );
  }
  function ElementCard(props) {
    return props.operation.map((element) => {
      return (
        <div className="flex justify-items-start pt-2">
          <div className="ml-4 w-20">
            {element.city === "nador" ? "الناظور" : ""}
            {element.city === "hoceima" ? "الحسمية" : ""}
          </div>
          <div className="ml-4 w-48">{element.court}</div>
          <div className="ml-4 w-32">
            {element.file.number}/{element.file.code}/{element.file.year}
          </div>
          <div className="ml-4 w-20">
            {element.case_type ? element.case_type.name : ""}
          </div>
          <div className="ml-4 w-20">{element.stamp.name}</div>
          <div className="ml-4 w-20">{element.amount} درهم</div>
          <div className="ml-4 w-40">
            {moment(element.created_at.toString()).format("L")}
          </div>
        </div>
      );
    });
  }
  function Card() {
    if (!mystate.search.audit || !mystate.search.audit.operation) {
      mystate.search.audit = { operation: [] };
    }
    return (
      <div className="bg-gray-200 w-[880px] p-4  text-black mb-12 font-semibold space-y-2">
        <ElementHeader />
        <div className="border-b-2 border-black" />
        <ElementCard operation={mystate.search.audit.operation} />
      </div>
    );
  }

  useEffect(() => {
    function submit(data) {
      axios
        .get(endpoint + "/insight/audit_operation/" + data.lawyer.code, {
          headers: { "x-token": data.lawyer.token },
        })
        .then(function (response) {
          dispatch({
            type: "search/SET_RESULT",
            payload: response.data.payload,
          });
          let sales = response.data.payload.sales;
          let total_amount = 0;
          sales.forEach((element) => {
            total_amount = total_amount + element.amount;
          });
          setTotal_amount_sales(total_amount);
        })
        .catch((error) => {
          return console.log("error");
        });
    }
    document.documentElement.dir = "rtl";
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    if (data) {
      dispatch({ type: "lawyer/SET", payload: data.lawyer });
      submit(data);
    }

    if (!data) {
      return navigate("/login");
    }

    window.scrollTo(0, 0);
  }, [mystate.lawyer._id]);

  return (
    <div className="container mx-auto mt-8 noselect font-cairo mb-12">
      <TopNavigation lawyer={mystate.lawyer} />
      <div className="border-b-2 border-gray-200 mt-12 w-full" />

      {mystate.search.lawyer && (
        <div className="text-2xl mb-12 font-semibold mt-16">
          إشعار بمقتنيات الدمغة
        </div>
      )}
      {mystate.search.sales && (
        <div>
          <div className="bg-mygreen lg:w-[400px] mt-12 p-4  text-white mb-12 font-semibold space-y-2">
            <div className="flex justify-items-start text-xl mb-8">
              <div className="w-[200px]">عمليات الاقتناء</div>
            </div>
            <div className="flex justify-items-start text-xl mb-6">
              <div className="w-[200px]">المجموع الاجمالي</div>
              <div>{total_amount_sales} درهم</div>
            </div>
            <div className="flex justify-items-start  text-xl">
              <div className="w-[200px]">العدد</div>
              <div className="w-[40px]">{mystate.search.sales.length}</div>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">
                    <div className="font-bold font-cairo">فئة الدمغة</div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="font-bold font-cairo">وحدات</div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="font-bold font-cairo">
                      الرقم التسلسي للدمغة
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="font-bold font-cairo">طريقة الاداء</div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="font-bold font-cairo">تاريخ العملية</div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="font-bold font-cairo">مدينة</div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="font-bold font-cairo">نقطة البيع</div>
                  </TableCell>
                  <TableCell align="right">
                    <div className="font-bold font-cairo">المبلغ الإجمالي</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              {mystate.search.sales.length && (
                <TableBody>
                  {mystate.search.sales.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="right">
                        <div
                          className={
                            row.item.price == "500"
                              ? "font-cairo font-semibold text-orange-600"
                              : "font-cairo font-semibold text-blue-600"
                          }
                        >
                          ({row.item.price})
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">{row.quantity}</div>
                      </TableCell>
                      <TableCell align="right">
                        <div
                          className={
                            row.item.price == "500"
                              ? "font-cairo font-semibold text-orange-600"
                              : "font-cairo font-semibold text-blue-600"
                          }
                        >
                          {row.stamp_serie.from} - {row.stamp_serie.to}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {row.payment.p_type === "check" ? "شيك" : "نقد"}
                        </div>
                        <div className="">{row.payment.p_code}</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {moment(row.created_at.toString()).format("L")}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {row.city}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {row.zone && row.zone.toUpperCase()}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo bg-mygreen py-1 px-2 font-bold text-white">
                          {row.amount} درهم
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
