import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Sell from "./screens/Sell";
import Login from "./screens/Login";
import Home from "./screens/Home";
import Dashboard from "./screens/Dashboard";
import DashboardMonth from "./screens/DashboardMonth";
import Create from "./screens/create";
import Audit from "./screens/Audit";
//import Search from "./screens/Search";
import Search from "./screens/SearchV2";
//import AuditInsight from "./screens/AuditInsight";
import AuditInsight from "./screens/AuditInsightV2";
import LoginLawyer from "./screens/lawyer/Login";
import HomeLawyer from "./screens/lawyer/Home";
import PenalityLawyer from "./screens/lawyer/Penality";
import SalesLawyer from "./screens/lawyer/Sales";
import CreateAccount from "./screens/secret/CreateAccount";
import Correction from "./screens/Correction";
import LandingPage from "./screens/LandingPage";
import CorrectionMistake from "./screens/CorrectionMistake";
import FilesState from "./screens/lawyer/FilesState";
import CorrectionPenality from "./screens/CorrectionPenality";
import CorrectionWithSearch from "./screens/CorrectionWithSearch";

export default function App() {
  return (
    <Router>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/309" element={<CreateAccount />} />
          <Route path="/correction" element={<Correction />} />
          <Route path="/correction_mistake" element={<CorrectionMistake />} />
          <Route path="/correction_penality" element={<CorrectionPenality />} />
          <Route path="/correctionWithSearch" element={<CorrectionWithSearch />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/penality_lawyer" element={<PenalityLawyer />} />
          <Route path="/auditinsight_lawyer" element={<FilesState />} />
          <Route path="/sales_sawyer" element={<SalesLawyer />} />
          <Route path="/login_lawyer" element={<LoginLawyer />} />
          <Route path="/home_lawyer" element={<HomeLawyer />} />
          <Route path="/search" element={<Search />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/month" element={<DashboardMonth />} />
          <Route path="/100" element={<Create />} />
          <Route path="/audit" element={<Audit />} />
          <Route path="/auditinsight" element={<AuditInsight />} />
        </Routes>
      </Provider>
    </Router>
  );
}
