const lawyerReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "lawyer/SET":
      return payload;
    default:
      return state;
  }
};

export default lawyerReducer;
