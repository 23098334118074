import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import LawyerUpdatePasswordModal from "../../components/LawyerUpdatePasswordModal";
import TopBar from "./TopBar";

function MyModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      centered
      backdrop="static"
    >
      <Modal.Body
          as={LawyerUpdatePasswordModal}
          user={props.user}
          show={props.show}
          onHide={() => props.onHide()}/>
    </Modal>
  );
}
export default function HomeLawyer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading]= useState(true)
  const [modalVisible, setModalVisible]= useState(false)
  const mystate = useSelector((state) => state);

  useEffect(() => {
    document.documentElement.dir = "rtl";
    window.scrollTo(0, 0);
    function loadLocalStorage() {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (data) {
        setLoading(false)
        dispatch({ type: "lawyer/SET", payload: data.lawyer });
      } else if (!data) {
        setLoading(false)
        return navigate("/login_lawyer");
      }
    }
    loadLocalStorage();
  }, [loading]);

  if (!loading)
    return (
      <div className="container mx-auto mt-8 noselect font-cairo lg:w-[800px] px-2">
        {!mystate.lawyer.is_password_changed && 
          <MyModal
          user={mystate.lawyer}
          show={true}
          onHide={() => setModalVisible(false)}
        />
        }
        <TopBar lawyer={mystate.lawyer} />
        <div className="flex flex-col mt-16">
          <div className=" text-2xl mb-12 font-semibold">اختيارات</div>
          <div>
            <div
              onClick={() => navigate("/penality_lawyer")}
              className="bg-red-400 w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer"
            >
              <div className="flex justify-between text-xl">
                <div className="">محضر إشعار بملفات دون دمغة</div>
              </div>
            </div>
            <div
              onClick={() => navigate("/auditinsight_lawyer")}
              className="bg-blue-600 w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer"
            >
              <div className="flex justify-between text-xl">
                <div className="">وضعية الملفات بعد التسوية</div>
              </div>
            </div>
            <div
              onClick={() => navigate("/sales_sawyer")}
              className="bg-mygreen w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer"
            >
              <div className="flex justify-between text-xl">
                <div className="">إشعار بمقتنيات الدمغة</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return <div></div>;
}
