import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../components/TopBar";

export default function Home() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const [loading, setLoading]=useState(true)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDashboardMenu, setShowDashboardMenu]= useState(false)
  const [showCorrectionMenu, setShowCorrectionMenu]= useState(false)

  useEffect(() => {
    document.documentElement.dir = "rtl";
    window.scrollTo(0, 0);
    function loadLocalStorage() {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (data) {
        if(data.user) {
          dispatch({type:"user/SET",payload:data.lawyer})
          setLoading(false)
        }
        else if(data.lawyer){
          setLoading(false)
          return navigate("/home_lawyer")
        }
      }
      else if (!data) {
        setLoading(false)
        return navigate("/login")
      }
    }

  if(loading) loadLocalStorage()

  },[loading]);
  
  if (loading) {
    return <div className="grid place-content-center text-3xl"/>;
  }
  else if(!loading){
    return (
      <div className="mx-auto mt-8 noselect font-cairo lg:w-[800px] px-2">
        <TopBar user={data.user} />
        <div className="flex flex-col mt-16">
          <div className="text-2xl mb-12 font-semibold">اختيارات</div>
          <div className="lg:flex space-x-2">
          <div className="mx-4">
            {data.user.is_taxpayer &&
                <div>
                  <div
                  onClick={() => navigate("/search")}
                  className="bg-blue-500 lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer hover:bg-blue-600"
                >
                  <div className="flex justify-between text-xl">
                    <div className="">محرك بحث المحامي</div>
                  </div>
                </div>
                <div
                  onClick={() => setShowCorrectionMenu(!showCorrectionMenu)}
                  className="bg-mygreen lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer relative"
                >
                  <div className="flex justify-between text-xl">
                    <div className="">تصحيح الدمغة</div>
                  </div>
                  {(showCorrectionMenu) &&
                  <div className="absolute top-16  py-4 lg:w-[360px] right-0 bg-mygreen ">
                    <div className="border-b-2 -mt-4  border-green-800"/>
                    <div onClick={() => navigate("/correction")} className="my-4 text-xl cursor-pointer px-2 py-1 hover:bg-green-800">
                      <div className=""> بأداء القيمة</div>
                    </div>
                    <div onClick={() => navigate("/correction_mistake")} className="mb-4 text-xl flex justify-between cursor-pointer px-2 py-1 hover:bg-green-800">
                      <div className=""> بتدارك الخطأ</div>
                    </div>
                    <div onClick={()=>navigate("/correction_penality")} className="text-xl flex justify-between cursor-pointer px-2 py-1 hover:bg-green-800">
                      <div className="">التصحيح  بالإقتطاع</div>
                    </div>
                  </div>
                  }
                </div>
              </div>
              }
            {(data.user.is_treasurer || data.user.is_admin) && (
              <div>
                <div
                  onClick={() => navigate("/search")}
                  className="bg-blue-500 lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer hover:bg-blue-600"
                >
                  <div className="flex justify-between text-xl">
                    <div className="">محرك بحث المحامي</div>
                  </div>
                </div>
                <div
                  onClick={() => navigate("/correctionWithSearch")}
                  className="bg-black lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer"
                >
                  <div className="flex justify-between text-xl">
                    <div className="">محرك بحث دمغة المحامي</div>
                  </div>
                </div>
                <div
                onClick={() => setShowDashboardMenu(!showDashboardMenu)}
                className="bg-mygreen lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer relative"
                >
                  <div  className="flex justify-between text-xl ">
                    <div className="">الاطلاع على مداخيل الدمغة</div>
                  </div>
                  {showDashboardMenu &&
                  <div className="absolute top-16  py-4 lg:w-[360px] right-0 bg-mygreen ">
                    <div className="border-b-2 -mt-4  border-green-800"/>
                    <div onClick={() => navigate("/dashboard")} className="my-4 text-xl cursor-pointer px-2 py-1 hover:bg-green-800">
                    <div className="">المداخيل اليومية</div>
                  </div>
                  <div onClick={() => navigate("/dashboard/month")} className="text-xl flex justify-between cursor-pointer px-2 py-1 hover:bg-green-800">
                    <div className="">المداخيل الشهرية</div>
                  </div>
                  </div>
                  }
                  
                </div>
    
                <div
                  onClick={() => navigate("/auditinsight")}
                  className="bg-red-400 lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer"
                >
                  <div className="flex justify-between text-xl">
                    <div className="">الاطلاع على الملفات بدون دمغة</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mx-4">
            {data.user.is_seller && 
              <div
              onClick={() => navigate("/search")}
              className="bg-blue-500 lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer hover:bg-blue-600"
            >
              <div className="flex justify-between text-xl">
                <div className="">محرك بحث المحامي</div>
              </div>
            </div>
            
            }
            {data.user.is_seller && 
            <div
            onClick={() => navigate("/correctionWithSearch")}
            className="bg-black lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer"
          >
            <div className="flex justify-between text-xl">
              <div className="">محرك بحث دمغة المحامي</div>
            </div>
          </div>
            
            }
            {(data.user.is_seller || data.user.is_admin) && (
              <div>
                <div
                  onClick={() => navigate("/sell")}
                  className="bg-mygreen lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer"
                >
                  <div className="flex justify-between text-xl">
                    <div className="">بيع الدمغة</div>
                  </div>
                </div>
                <div
                  onClick={() => setShowCorrectionMenu(!showCorrectionMenu)}
                  className="bg-mygreen lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer relative"
                >
                  <div className="flex justify-between text-xl">
                    <div className="">تصحيح الدمغة</div>
                  </div>
                  {(showCorrectionMenu && data.user.is_admin) &&
                  <div className="absolute top-16  py-4 lg:w-[360px] right-0 bg-mygreen ">
                    <div className="border-b-2 -mt-4  border-green-800"/>
                    <div onClick={() => navigate("/correction")} className="my-4 text-xl cursor-pointer px-2 py-1 hover:bg-green-800">
                      <div className=""> بأداء القيمة</div>
                    </div>
                    <div onClick={() => navigate("/correction_mistake")} className="mb-4 text-xl flex justify-between cursor-pointer px-2 py-1 hover:bg-green-800">
                      <div className=""> بتدارك الخطأ</div>
                    </div>
                    <div onClick={()=>navigate("/correction_penality")} className="text-xl flex justify-between cursor-pointer px-2 py-1 hover:bg-green-800">
                      <div className="">التصحيح  بالإقتطاع</div>
                    </div>
                  </div>
                  }
                  {(showCorrectionMenu && data.user.is_seller) &&
                  <div className="absolute top-16  py-4 lg:w-[360px] right-0 bg-mygreen ">
                    <div className="border-b-2 -mt-4  border-green-800"/>
                    <div onClick={() => navigate("/correction")} className="my-4 text-xl cursor-pointer px-2 py-1 hover:bg-green-800">
                      <div className=""> بأداء القيمة</div>
                    </div>
                    <div onClick={() => navigate("/correction_mistake")} className="mb-4 text-xl flex justify-between cursor-pointer px-2 py-1 hover:bg-green-800">
                      <div className=""> بتدارك الخطأ</div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            )}
            {(data.user.is_audit || data.user.is_admin) && (
              <div>
                <div
                  onClick={() => navigate("/audit")}
                  className="bg-red-400 lg:w-[360px] p-4  text-white mb-12 font-semibold space-y-2 cursor-pointer"
                >
                  <div className="flex justify-between text-xl">
                    <div className="">تسجيل ملف بدون دمغة</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          </div>
        </div>
      </div>
    );
  }
 
}
