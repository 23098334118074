// React imports
import { useDispatch, useSelector, } from "react-redux"
import { useNavigate } from "react-router-dom";

// Icons imports
import closeIcon from "../assets/close.svg"

// External components
import axios from "axios"

// Internal components
import { endpoint } from "../env";
import show_icon from "../assets/viewer.png";
import hide_icon from "../assets/hidden.png";
import { useState } from "react";

export default function LawyerUpdatePasswordModal(props){

    const [passwordShown, setPasswordShown] = useState(false);
    const [password, setPassword]= useState()
    const [passwordConfirm, setPasswordConfirm]= useState()
    const [loading, setLoading]=useState(false)
    const navigate = useNavigate()
    
    function tooglePassword() {
        setPasswordShown(!passwordShown);
      }

    function handleSubmit(){
        console.log("submit")
        setLoading(true)
        axios.put(endpoint + "/lawyer/password",{password:password}, {
            headers: { "x-token": props.user.token },
          })
          .then(function (response) {
            setLoading(false)
            logout()
          })
          .catch((error) => {
            setLoading(false)
          });
    }

    function logout() {
        console.log("lading page")
        localStorage.removeItem("damghaState");
        return navigate("/");
      }

    if(!props.show) return <></>
    else return (
        <div className="grid place-content-center font-cairo">
            <div className=" bg-white flex flex-col border-2 border-black lg:h-[454px] lg:w-[650px] rounded-[22px] px-12 py-[47px] relative">
                <div className="absolute top-[28px] right-[30px] cursor-pointer bg-[#262626] hover:bg-gray-400 h-[28px] w-[28px] grid place-content-center rounded-full">
                    <img src={closeIcon} className="h-[10px] w-[10px]" alt="close_icon"/>
                </div>
                <div className="flex flex-col grow">
                    <div className="mx-4 text-xl font-semibold font-regular font-IBM-Plex-Sans">مرحبا، من اجل ضمان أمن حسابكم المرجو تغيير كلمة المرور!</div>
                    <div className="flex flex-col justify-between mx-2">
                        <div className="flex flex-col mt-12 space-y-4">
                            <div className="text-[20px] font-regular font-IBM-Plex-Sans">كلمة المرور الجديدة</div>
                            <div className="flex items-center relative w-52 mb-16">
                                <input
                                type={passwordShown ? "text" : "password"}
                                className="w-full bg-gray-50 font-semibold h-12 rounded-lg px-4 ml-12"
                                placeholder="********"
                                onChange={(e) =>setPassword(e.target.value)}
                                value={password}
                                />
                                <img
                                src={passwordShown ? show_icon : hide_icon}
                                className="h-6 left-0  cursor-pointer  absolute"
                                onClick={tooglePassword}
                                />
                            </div>
                        </div>
                        <div className="mt-4 space-y-4">
                            <div className="text-[20px] font-regular font-IBM-Plex-Sans">تأكيد كلمة المرور</div>
                            <div className="flex items-center relative w-52 mb-16">
                            <input
                                type={passwordShown ? "text" : "password"}
                                className="w-full bg-gray-50 font-semibold h-12 rounded-lg px-4 ml-12"
                                placeholder="********"
                                onChange={(e) =>setPasswordConfirm(e.target.value)}
                                value={passwordConfirm}
                                />
                                <img
                                src={passwordShown ? show_icon : hide_icon}
                                className="h-6 left-0  cursor-pointer  absolute"
                                onClick={tooglePassword}
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="pt-8 flex lg:space-x-8 justify-center space-x-2 flex-row-reverse">
                    <div
                        onClick={() => logout()}
                        className="bg-gray-800 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
                    >
                        تسجيل الخروج
                    </div>
                    {!loading &&
                    <div
                        className="bg-green-600 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer"
                        onClick={() => handleSubmit()}
                    >
                        تأكيد
                    </div>}
                    {loading &&
                    <div
                        className="bg-green-600 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-wait"
                        
                    >
                        ...تأكيد
                    </div>}
                </div>
            </div>
        </div>
       )
}