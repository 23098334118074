// React imports
import { useDispatch, useSelector } from "react-redux"

// Icons imports
import closeIcon from "../assets/close.svg"

// External components
import axios from "axios"

// Internal components
import { endpoint } from "../env";

export default function ConfirmationModal(props){
    function handleCancel(){
        console.log("HIDE MODAL CONFIRMATION")
        console.log(props)
        props.onHide()
    }

    function handleSubmit(){
        axios.post(endpoint + "/user/correct/" + props.codeAvocat, props.dataSubmit, {headers: { "x-token": props.user.token },})
        .then(function (response)
          {
            handleCancel()
            props.setOperationDone()
            if (props.canRemove) {
                props.removeObjectById()
            }
            
          }).catch((error) => {
          return console.log(error);
        });
        
    }

    if(!props.show) return <></>
    else return (
        <div className="grid place-content-center font-cairo">
            <div className=" bg-white flex flex-col border-2 border-black h-[454px] w-[650px] rounded-[22px] px-12 py-[47px] relative">
                <div onClick={()=>handleCancel()} className="absolute top-[28px] right-[30px] cursor-pointer bg-[#262626] hover:bg-gray-400 h-[28px] w-[28px] grid place-content-center rounded-full">
                    <img src={closeIcon} className="h-[10px] w-[10px]" alt="close_icon"/>
                </div>
                <div className="flex flex-col grow">
                    <div className="flex justify-between mx-2">
                        <div className="flex flex-col mt-12 space-y-4">
                            <div className="text-[20px] font-regular font-IBM-Plex-Sans">المحكمة</div>
                            <div className="font-regular text-[22px] font-IBM-Plex-Sans">{props.dataSubmit.court}</div>
                        </div>
                        <div className="mt-12 space-y-4">
                            <div className="text-[20px] font-regular font-IBM-Plex-Sans">مرجع الملف</div>
                            <div className="text-[28px] font-regular font-IBM-Plex-Sans">
                            {props.dataSubmit.file.number}/{props.dataSubmit.file.code}/{props.dataSubmit.file.year}
                            </div>
                        </div>
                        <div className="flex space-y-4 flex-col mt-12">
                            <div className="text-[20px] font-regular font-IBM-Plex-Sans">نوع القضية</div>
                            <div className="font-regular text-[22px] font-IBM-Plex-Sans">{props.dataSubmit.case_type.name}</div>
                        </div>
                    </div>
                    <div className="border-gray-400 mt-2 border-b-2"/>
                    <div className="flex flex-col mt-12">
                            <div className="text-[20px] font-regular font-IBM-Plex-Sans">مبلغ الأداء</div>
                            <div className="font-regular text-red-600 text-[22px] font-IBM-Plex-Sans">{props.dataSubmit.amount} درهم</div>
                    </div>
                </div>
                <div className="pt-8 flex lg:space-x-8 justify-center space-x-2 flex-row-reverse">
                    <div
                        onClick={() => handleCancel("/")}
                        className="bg-gray-800 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
                    >
                        إلغاء
                    </div>
                    <div
                        className="bg-green-600 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer"
                        onClick={() => handleSubmit()}
                    >
                       أداء القيمة
                    </div>
                </div>
            </div>
        </div>
       )
}