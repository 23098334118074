import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
    import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../env";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TopNavigation from "../components/TopNavigation";
import Paper from "@mui/material/Paper";
import PayPenalityModal from "../components/PayPenalityModal";
import Modal from 'react-bootstrap/Modal'
import * as moment from "moment";


function MyModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      centered
      backdrop="static"
    >
      <Modal.Body
          as={PayPenalityModal}
          user={props.user}
          setOperationDone={()=>props.setOperationDone()}
          dataSubmit={props.dataSubmit}
          codeAvocat={props.codeAvocat}
          show={props.show}
          onHide={() => props.onHide()}/>
    </Modal>
  );
}

export default function CorrectionPenality() {

    const mystate = useSelector((state) => state);
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    const [loading, setLoading]=useState(false)
    const [codeAvocat, setCodeAvocat] = useState();
    const [fileData, setFileData]=useState();
    const [payload, setPayload]=useState()
    const [modalVisible, setModalVisible]=useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [total_amount, setTotal_amount] = useState(0);

    function submit() {
        setLoading(true)
        let total=0
        axios.get(endpoint + "/user/search_penality_to_correct/" + codeAvocat, {
            headers: { "x-token": data.user.token },
        })
        .then(function (response) {
            setLoading(false)
            console.log(response.data)
            setPayload(response.data.payload)
            response.data.payload.operation.forEach(element => {
                if(element.amount===50)
                {
                    element.amount=150
                    total=total+150
                }
                else if(element.amount===500)
                {   
                    element.amount=1000
                    total=total+1000
                }
            });
            setTotal_amount(total)
        })
        .catch((error) => {
            setLoading(false)
            return console.log("error");
        });
    }

    function ElementHeader() {
        return (
        <div className="flex justify-items-start pt-2">
            <div className="ml-4 w-48">محكمة</div>
            <div className="ml-4 w-32">مرجع الملف</div>
            <div className="ml-4 w-20 ">القضية</div>
            <div className="ml-4 w-20">الدمغة</div>
            <div className="ml-4 w-40">المبلغ</div>
            <div className="ml-4 w-40">تاريخ العملية</div>
            <div className="ml-4 w-40">آخر اجل الأداء</div>
            <div className="ml-4 w-40">حالة الملف</div>
        </div>
        );
    }

    function ElementCard(props) {
      return props.operation.map((element) => {
        return (
            <div className="flex justify-items-start  pt-2">
            <div className="ml-4 w-48">{element.court}</div>
            <div className="ml-4 w-32">
                {element.file.number}/{element.file.code}/{element.file.year}
            </div>
            <div className="ml-4 w-20 ">
                {element.case_type ? element.case_type.name : ""}
            </div>
            <div className="ml-4 w-20">{element.stamp.name}</div>
            <div className="ml-4 w-40">{element.amount} درهم</div>
            <div className="ml-4 w-40">
            {moment(element.created_at.toString()).format("DD/MM/YY")}
            </div>
            <div className="ml-4 w-40">
            {moment(element.dueDate_at.toString()).format("DD/MM/YY")}
            </div>
            {element.state==="pending" && <div className="ml-4 w-40">
            {moment(element.dueDate_at).diff(moment(Date.now()),"days")<0 && <div onClick={()=>{props.showModal(); props.setFileData(element)}} className="ml-4 w-40 text-red-400 cursor-pointer">إحالة على الإقتطاع</div>}
            </div>}
            
            </div>
        );
        });
    }

    function Card(props) {
        if (!payload.audit || !payload.audit.operation) {
        payload.audit = { operation: [] };
        }
        return (
        <div className="bg-gray-200 p-4  text-black mb-12 font-semibold space-y-2">
            <ElementHeader />
            <div className="border-b-2 border-black" />
            <ElementCard operation={payload.operation} showModal={()=>props.showModal()} setFileData={(e)=>props.setFileData(e)}/>
        </div>
        );
    }

    useEffect(() => {
        document.documentElement.dir = "rtl";
        const localstorage = localStorage.getItem("damghaState");
        const data = JSON.parse(localstorage);
        if (!data) {
        return navigate("/login");
        }

        if (!mystate.user._id) {
        const localstorage = localStorage.getItem("damghaState");
        const data = JSON.parse(localstorage);
        if (!data) {
            return navigate("/login");
        }
        }
        window.scrollTo(0, 0);
    }, [mystate.user._id]);

  return (
    <div className="container mx-auto mt-8 noselect font-cairo mb-12">
      <TopNavigation user={data.user} />
      <MyModal
        dataSubmit={fileData}
        codeAvocat={codeAvocat}
        show={modalVisible}
        onHide={() => setModalVisible(false)}
      />
      <div className="text-2xl mb-12 font-semibold mt-16">التصحيح بالإقتطاع</div>

      <div className="flex  space-x-2 justify-end flex-row-reverse">
        <input
          type="text"
          className="bg-mygray border-2 border-gray-200 rounded-md h-12 text-center text-lg w-[200pxa]"
          onChange={(e) => setCodeAvocat(e.target.value)}
          value={codeAvocat}
        />
        {!loading && codeAvocat &&
            <div
            className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center rounded-md cursor-pointer hover:bg-blue-400"
            onClick={() => submit()}
            >
            بحث
            </div>
        }
        {loading &&
            <div className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center rounded-md cursor-wait">
            جاري البحث
            </div>
        }
        {!codeAvocat &&
            <div className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center cursor-not-allowed opacity-60 rounded-md">
            بحث 
            </div>
        }
      </div>
      <div className="border-b-2 border-gray-200 mt-12 w-full" />

      {payload && payload.lawyer && (
        <div className="text-2xl mb-12 font-semibold mt-16">
          {payload.lawyer.name}
        </div>
      )}
      {payload && payload.operation && (
        <div>
          <div className="bg-red-400 w-[400px] mt-12 p-4  text-white mb-12 font-semibold space-y-2">
            <div className="flex justify-items-start text-xl mb-8">
              <div className="w-[200px]">ملفات الإقتطاع</div>
            </div>
            <div className="flex justify-items-start text-xl mb-6">
              <div className="w-[200px]">المجموع الاجمالي</div>
              <div>{total_amount} درهم</div>
            </div>
            <div className="flex justify-items-start  text-xl">
              <div className="w-[200px]">عدد الملفات</div>
              <div className="w-[40px]">
                {payload.operation.length}
              </div>
            </div>
          </div>
          <Card setFileData={(e)=>setFileData(e)} showModal={()=>setModalVisible(true)}/>
        </div>
      )}
      
    </div>
  );
}
