import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import healthBgIcon from "../assets/health-bg.jpg"
import logo from "../assets/logo-dark.png"
import logo_avocat from "../assets/avocat-logo.png" 

export default function LandingPage() {
  const navigate = useNavigate()
  const [loading, setLoading]= useState(true)
  
  useEffect(() => {
    document.documentElement.dir = "rtl";
    window.scrollTo(0, 0);
    function loadLocalStorage() {
        const localstorage = localStorage.getItem("damghaState");
        const data = JSON.parse(localstorage);
        if (data) {
          if(data.user) {
            setLoading(false)
            return navigate("/home")
          }
          else if(data.lawyer){
            setLoading(false)
            return navigate("/home_lawyer")
          }
        }
        else if (!data) {
          setLoading(false)
          
        }
      }
      if(loading) loadLocalStorage()
    
  }, [loading]);

  
  return (
    <div className="flex flex-col py-6  mx-auto container font-cairo min-h-screen">
        <div className="flex justify-between items-center">
          <div onClick={()=>navigate("/login")} className="text-center text-lg cursor-pointer font-semibold py-2 rounded-md text-white bg-black w-[120px]">حساب النقابة</div>
          <div className=""><img src={logo_avocat} className="w-20 opacity-80" alt="avocat nador" title="avocat Nador"/></div>
        </div>
        <div className="lg:flex md:flex grow items-center">
            <div className="w-5/12">
                <div className="text-6xl leading-relaxed font-bold mb-10">
                    الدمغة...دعم<br/> للتأمين الصحي
                </div>
                <div onClick={()=>navigate("/login_lawyer")} className="bg-[#6C63FF] text-xl font-semibold text-center w-[200px] py-3 text-white rounded-md hover:bg-purple-800 cursor-pointer">حساب المحامي</div>
            </div>
            <div className="lg:w-7/12 md:w-7/12 lg:mt-0 mt-4 w-0">
                <img src={healthBgIcon} className="h-auto w-auto" />
            </div>
        </div>
        <a href="https://vosch.tech/" className="cursor-pointer grid place-content-center opacity-80"><img src={logo} className="w-20"/></a>
    </div>
  );
}
