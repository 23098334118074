import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../env";

import logo from "../logo.svg";

export default function Sell() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const [operationDone, setOperationDone] = useState(false);
  const [operationEr, setOperationEr] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [option, SetOption] = useState({
    optionOne: true,
    optionTwo: false,
    value: 50,
  });
  const [optionPay, SetOptionPay] = useState({
    optionM: true,
    optionC: false,
  });
  const [paymentCode, setPaymentCode] = useState("");
  const [codeAvocat, setCodeAvocat] = useState();
  const [nameAvocat, setNameAvocat] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [totalAmount, setTotalAmount] = useState(50);
  const [dataResponse, setDataResponse] = useState({});
  function resetValue() {
    setCodeAvocat("");
    setNameAvocat("");
    setOperationEr(false);
  }
  function logout() {
    localStorage.removeItem("damghaState");
    return navigate("/login");
  }

  function submit() {
    setOperationEr(false);
    let dataSubmit = {
      code: codeAvocat,
      name: nameAvocat,
    };
    axios
      .post(endpoint + "/user/register_lawyer/", dataSubmit)
      .then(function (response) {
        setDataResponse(response.data.payload.lawyer);

        resetValue();
        return setOperationDone(true);
      })
      .catch((error) => {
        setOperationEr(true);
        return console.log(error);
      });
  }

  useEffect(() => {
    if (mystate.user._id) {
      const localstorage = localStorage.getItem("damghaState");

      const data = JSON.parse(localstorage);
      if (!data) {
        return navigate("/login");
      }
      axios
        .get(endpoint + "/user", {
          headers: { "x-token": data.user.token },
        })
        .then(function (response) {
          dispatch({ type: "setUser", payload: response.data.payload.user });
        })
        .catch((error) => {
          return console.log("error");
        });
    } else if (!mystate.user._id) {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (!data) {
        return navigate("/login");
      }
      axios
        .get(endpoint + "/user", {
          headers: { "x-token": data.user.token },
        })
        .then(function (response) {
          dispatch({ type: "setUser", payload: response.data.payload.user });
        })
        .catch((error) => {
          return console.log(error);
        });
    }
    window.scrollTo(0, 0);
  }, [mystate.user._id]);

  return (
    <div className="flex flex-col  container lg:mx-auto mt-8   lg:w-[800px] px-4 font-cairo  ">
      <div className="justify-between flex lg:flex-row flex-col">
        <div className="text-lg font-semibold">
          Utilisateur connecté - {mystate.user.name}
        </div>
        <div
          className="text-lg font-semibold cursor-pointer"
          onClick={() => logout()}
        >
          Se déconnecter
        </div>
      </div>
      {!operationDone && (
        <div className="flex flex-col space-y-4 mt-20">
          <div className="flex items-center space-x-4">
            <div className="">
              <div className="text-lg font-cairo">Code avocat</div>
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 "
                onChange={(e) => setCodeAvocat(e.target.value)}
                value={codeAvocat}
              />
            </div>
            {operationEr && (
              <div className="text-red-400 font-semibold text-lg">
                déjà inscrit
              </div>
            )}
          </div>
          <div className="flex flex-col space-x-2">
            <div className="text-lg font-cairo">Nom avocat</div>
            <input
              type="text"
              className="bg-mygray border-2 border-gray-200 rounded-md h-12 text-right text-lg w-60 "
              onChange={(e) => setNameAvocat(e.target.value)}
              value={nameAvocat}
            />
          </div>
          <div className="pt-16 flex lg:space-x-8 space-x-2 w-full">
            <div
              onClick={() => resetValue()}
              className="bg-gray-800 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
            >
              Annuler
            </div>
            <div
              className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
              onClick={() => submit()}
            >
              Enregistrer
            </div>
          </div>
        </div>
      )}
      {operationDone && (
        <div className="mt-20 ">
          <div className="text-xl">Opération réalisée avec succès</div>
          <div className="flex flex-col mt-8 space-y-4 text-lg bg-green-400 py-8 px-4 rounded-lg mb-4">
            <div classname=" font-semibold">
              Code d'Avocat : {dataResponse.code}
            </div>
            déjà inscrit
            <div classname=" font-semibold">
              Nom d'Avocat : {dataResponse.name}
            </div>
          </div>
          <div
            className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
            onClick={() => {
              setOperationDone(false);
              navigate("/100");
            }}
          >
            Nouveau
          </div>
        </div>
      )}
    </div>
  );
}
