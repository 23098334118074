import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../env";
import ConfirmationModal from "../components/ConfirmationModal";
import CorrectMistakeModal from "../components/CorrectMistakeModal";
import TopNavigation from "../components/TopNavigation";
import Modal from 'react-bootstrap/Modal'
import * as moment from "moment";


function MyModalCorrection(props) {
    return (
      <Modal
        {...props}
        size="sm"
        centered
        backdrop="static"
      >
        <Modal.Body
            as={ConfirmationModal}
            user={props.user}
            setOperationDone={()=>props.setOperationDone()}
            dataSubmit={props.dataSubmit}
            codeAvocat={props.codeAvocat}
            removeObjectById={()=>props.removeObjectById()}
            canRemove={true}
            show={props.show}
            onHide={() => props.onHide()}/>
      </Modal>
    );
  }

  function MyModalCorrectionMistake(props) {
    return (
      <Modal
        {...props}
        size="sm"
        centered
        backdrop="static"
      >
        <Modal.Body
            as={CorrectMistakeModal}
            user={props.user}
            setOperationDone={()=>props.setOperationDone()}
            dataSubmit={props.dataSubmit}
            codeAvocat={props.codeAvocat}
            removeObjectById={()=>props.removeObjectById()}
            canRemove={true}
            show={props.show}
            onHide={() => props.onHide()}/>
      </Modal>
    );
  }
export default function CorrectionWithSearch() {

    const mystate = useSelector((state) => state);
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    const [loading, setLoading]=useState(false)
    const [codeAvocat, setCodeAvocat] = useState();
    const [fileData, setFileData]=useState();
    const [operationDone, setOperationDone] = useState(false);
    const [payload, setPayload]=useState()
    const [modalVisible, setModalVisible]=useState()
    const [modalMistakeVisible, setModalMistakeVisible]=useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [total_amount, setTotal_amount] = useState(0);
    
    function removeObjectById(idToRemove) {
        setPayload(prevPayload => ({
            ...prevPayload,
            operation: prevPayload.operation.filter(item => item._id.toString() !== idToRemove.toString())
        }));
    }
    
    function submit() {
        setLoading(true)
        let total=0
        axios.get(endpoint + "/user/search_case_to_correct/" + codeAvocat, {
            headers: { "x-token": data.user.token },
        })
        .then(function (response) {
            setLoading(false)
            setPayload(response.data.payload)
            console.log(response.data.payload.operation)
            response.data.payload.operation.forEach(element => {
                total = total + element.amount;
            });
            setTotal_amount(total)
        })
        .catch((error) => {
            setLoading(false)
            return console.log("error");
        });
    }
    function submitCorrect() {
        const case_type={name:""}
        const codeAvocat=""
        const year=""
        const code =""
        const number=""
        const selectedCourt=""
        if(case_type.name==="" || codeAvocat==="" || year==="" || code==="" || number==="")
        return console.log("Please make sure to add all informations")
        
        let dataSubmit = {
          court: selectedCourt,
          file: {
            year: "year",
            code: "code",
            number: "number",
          },
          case_type:{
            code:"case_type_selected.code",
            name:"case_type_selected.name"
          },
        };
       
      }
    function ElementHeader() {
        return (
        <div className="flex justify-items-start pt-2">
            <div className="flex ml-4 w-32">محكمة</div>
            <div className="ml-4 w-32">مرجع الملف</div>
            <div className="ml-4 w-20 ">القضية</div>
            <div className="ml-4 w-20">الدمغة</div>
            <div className="ml-4 w-32">المبلغ</div>
            <div className="ml-4 w-40">تاريخ العملية</div>
            <div className="ml-4 w-40">آخر اجل الأداء</div>
            <div className="ml-4 w-[216px]">تصحيح الملف</div>
        </div>
        );
    }

    function ElementCard({operation, showModal, showModalMistake ,setFileData}) {
        function handleClick(element){
            setFileData(element)
            showModal()
        }
        function handleClickMistake(element){
            setFileData(element)
            showModalMistake()
        }
      return operation.map((element) => {
        console.log(moment(element.dueDate_at).diff(moment(Date.now()),"days"))
        return (
            <div className="flex justify-items-start  pt-2">
                <div className="flex ml-4 w-32">{element.court}</div>
                <div className="ml-4 w-32">
                    {element.file.number}/{element.file.code}/{element.file.year}
                </div>
                <div className="ml-4 w-20 ">
                    {element.case_type ? element.case_type.name : ""}
                </div>
                <div className="ml-4 w-20">{element.stamp.name}</div>
                <div className="ml-4 w-32">{element.amount} درهم</div>
                <div className="ml-4 w-40">
                {moment(element.created_at.toString()).format("DD/MM/YY")}
                </div>
                <div className={`ml-4 w-40 ${moment(element.dueDate_at).diff(moment(Date.now()),"days")<0?" text-red-500":""}`}>
                {moment(element.dueDate_at.toString()).format("DD/MM/YY")}
                </div>
                {(moment(element.dueDate_at).diff(moment(Date.now()),"days")<0 && !data.user.is_admin) && <div className="ml-4 w-40 text-red-400">إحالة على الإقتطاع</div>}
                {(moment(element.dueDate_at).diff(moment(Date.now()),"days")>=0 || data.user.is_admin)
                &&
                <div className="flex ml-4 w-[216px]">
                    <div onClick={()=>handleClick(element)} className="w-[100px] px-2 py-2 bg-emerald-400 rounded-md cursor-pointer"> بأداء القيمة</div>
                    <div onClick={()=>handleClickMistake(element)} className="w-[100px] px-2 py-2 bg-blue-400 rounded-md mr-4 cursor-pointer"> بتدارك الخطأ</div>
                </div>}
            </div>
        );
        });
    }

    function Card({showModal, showModalMistake, setFileData}) {
        if (!payload.audit || !payload.audit.operation) {
        payload.audit = { operation: [] };
        }
        return (
        <div className="bg-gray-200 p-4  text-black mb-12 font-semibold space-y-2">
            <ElementHeader />
            <div className="border-b-2 border-black" />
            <ElementCard operation={payload.operation} showModal={()=>showModal()} showModalMistake={()=>showModalMistake()} setFileData={(e)=>setFileData(e)}/>
        </div>
        );
    }

    useEffect(() => {
        document.documentElement.dir = "rtl";
        const localstorage = localStorage.getItem("damghaState");
        const data = JSON.parse(localstorage);
        if (!data) {
        return navigate("/login");
        }

        if (!mystate.user._id) {
        const localstorage = localStorage.getItem("damghaState");
        const data = JSON.parse(localstorage);
        if (!data) {
            return navigate("/login");
        }
        }
        window.scrollTo(0, 0);
    }, [mystate.user._id]);

  return (
    <div className="container mx-auto mt-8 noselect font-cairo mb-12">
      <TopNavigation user={data.user} />
      <MyModalCorrection
        dataSubmit={fileData}
        codeAvocat={codeAvocat}
        setOperationDone={()=>setOperationDone(true)}
        removeObjectById={()=>removeObjectById(fileData._id)}
        canRemove={true}
        user={data.user}
        show={modalVisible}
        onHide={() => setModalVisible(false)}
      />
      <MyModalCorrectionMistake
        dataSubmit={fileData}
        codeAvocat={codeAvocat}
        setOperationDone={()=>setOperationDone(true)}
        removeObjectById={()=>removeObjectById(fileData._id)}
        canRemove={true}
        user={data.user}
        show={modalMistakeVisible}
        onHide={() => setModalMistakeVisible(false)}
      />
      <div className="text-2xl mb-12 font-semibold mt-16">محرك بحث دمغة المحامي</div>

      <div className="flex  space-x-2 justify-end flex-row-reverse">
        <input
          type="text"
          placeholder="ادخل رمز المحامي"
          className="bg-mygray border-2 border-gray-200 rounded-md h-12 text-center text-lg w-[200pxa]"
          onChange={(e) => setCodeAvocat(e.target.value)}
          value={codeAvocat}
        />
        {!loading && codeAvocat &&
            <div
            className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center rounded-md cursor-pointer hover:bg-blue-400"
            onClick={() => submit()}
            >
            بحث
            </div>
        }
        {loading &&
            <div className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center rounded-md cursor-wait">
            جاري البحث
            </div>
        }
        {!codeAvocat &&
            <div className="bg-myblue text-white text-xl font-semibold w-28 h-12 grid place-items-center cursor-not-allowed opacity-60 rounded-md">
            بحث 
            </div>
        }
      </div>
      <div className="border-b-2 border-gray-200 mt-12 w-full" />

      {payload && payload.lawyer && (
        <div className="text-2xl mb-12 font-semibold mt-16">
          {payload.lawyer.name}
        </div>
      )}
      {payload && payload.operation && (
        <div>
          <div className="bg-red-400 w-[400px] mt-12 p-4  text-white mb-12 font-semibold space-y-2">
            <div className="flex justify-items-start text-xl mb-8">
              <div className="w-[200px]">ملفات الإقتطاع</div>
            </div>
            <div className="flex justify-items-start text-xl mb-6">
              <div className="w-[200px]">المجموع الاجمالي</div>
              <div>{total_amount} درهم</div>
            </div>
            <div className="flex justify-items-start  text-xl">
              <div className="w-[200px]">عدد الملفات</div>
              <div className="w-[40px]">
                {payload.operation.length}
              </div>
            </div>
          </div>
          <Card setFileData={(e)=>setFileData(e)} showModal={()=>setModalVisible(true)} showModalMistake={()=>setModalMistakeVisible(true)}/>
        </div>
      )}
      
    </div>
  );
}
