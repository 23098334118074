import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TopNavigation from "../components/TopNavigation";
import * as moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formControlLabelClasses, TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import { endpoint } from "../env";

export default function Dashboard() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading]=useState(true)
  const [insightsMonth, setInsightsMonth] = useState({
    timbre_50: 0,
    timbre_500: 0,
    totale: 0,
  });
  const [insights, setInsights] = useState({
    timbre_50: 0,
    timbre_500: 0,
    totale: 0,
  });

  function calcNumbers(dt) {
    let timbre_50 = 0;
    let timbre_500 = 0;
    let totale = 0;
    dt.forEach((element) => {
      totale = totale + element.amount;
      if (element.item.price == 500) timbre_500 = timbre_500 + element.amount;
      else if (element.item.price == 50) timbre_50 = timbre_50 + element.amount;
    });
    setInsights({
      timbre_50: timbre_50,
      timbre_500: timbre_500,
      totale: totale,
    });
  }

  useEffect(() => {
    document.documentElement.dir = "rtl";
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    if (!data) {
      return navigate("/login");
    }
    if(loading){
      axios.get(endpoint + "/insight/sales_operation", {headers: { "x-token": data.user.token },})
        .then(function (response) {setInsightsMonth(response.data.payload)})
        .catch((error) => {
          setLoading(false)
          return console.log("error");
        });

      axios.get(endpoint + "/insight/sales_operation/daily", {headers: { "x-token": data.user.token },})
        .then(function (response) {
          console.log(response.data.payload.sale)
          dispatch({ type: "setSale", payload: response.data.payload.sale });
          calcNumbers(response.data.payload.sale);
        })
        .catch((error) => {
          setLoading(false)
          return console.log("error");
        });
        setLoading(false)
    }
    window.scrollTo(0, 0);
  }, [loading]);

  return (
    <div className="container mx-auto mt-8 noselect font-cairo">
      <TopNavigation user={data.user} />

      <div className=" text-2xl mb-12 font-semibold mt-16">
        مجموع مداخيل الدمغة
      </div>
      <div className="bg-black lg:w-[620px] p-4  text-white mb-12 font-semibold space-y-2">
        <div className="flex justify-between text-xl">
          <div className="">المجموع الاجمالي الشهري</div>
          <div>{insightsMonth.total_amount} درهم</div>
        </div>
        <div className="flex justify-between">
          <div className="">فئة الدمغة (50) :</div>
          <div className="">وحدة : {insightsMonth.stamp_50? insightsMonth.stamp_50.amount/50 : 0}</div>
          <div>مبلغ : {insightsMonth.stamp_50?insightsMonth.stamp_50.amount:0} درهم</div>
        </div>
        <div className="flex justify-between">
          <div className="">فئة الدمغة (500)</div>
          <div className="">وحدة : {insightsMonth.stamp_500? insightsMonth.stamp_500.amount/500 : 0}</div>
          <div>مبلغ : {insightsMonth.stamp_500?insightsMonth.stamp_500.amount:0} درهم</div>
        </div>
      </div>

      <div className="bg-mygreen lg:w-[620px] p-4  text-white mb-12 font-semibold space-y-2">
        <div className="flex justify-between text-xl">
          <div className="">المجموع الاجمالي اليومي</div>
          <div>{insights.totale} درهم</div>
        </div>
        <div className="flex justify-between">
          <div className="">فئة الدمغة (50) :</div>
          <div className="">وحدة : {insights.timbre_50 / 50}</div>
          <div>مبلغ : {insights.timbre_50} درهم</div>
        </div>
        <div className="flex justify-between">
          <div className="">فئة الدمغة (500)</div>
          <div className="">وحدة : {insights.timbre_500 / 500}</div>
          <div>مبلغ : {insights.timbre_500} درهم</div>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">
                <div className="font-bold font-cairo">المكتب</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">فئة الدمغة</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">وحدات</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">
                  الرقم التسلسي للدمغة
                </div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">طريقة الاداء</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">تاريخ العملية</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">مد ينة</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">نقطة البيع</div>
              </TableCell>
              <TableCell align="right">
                <div className="font-bold font-cairo">المبلغ الإجمالي</div>
              </TableCell>
            </TableRow>
          </TableHead>
          {mystate.sale.length && (
            <TableBody>
              {mystate.sale.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="right">
                    <div className="font-cairo">{row.buyer.name}</div>
                  </TableCell>
                  <TableCell align="right">
                        <div
                          className={
                            row.item.price == "500"
                              ? "font-cairo font-semibold text-orange-600"
                              : "font-cairo font-semibold text-blue-600"
                          }
                        >
                          ({row.item.price})
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">{row.quantity}</div>
                      </TableCell>
                      <TableCell align="right">
                        <div
                          className={
                            row.item.price == "500"
                              ? "font-cairo font-semibold text-orange-600"
                              : "font-cairo font-semibold text-blue-600"
                          }
                        >
                          {row.stamp_serie.from} - {row.stamp_serie.to}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {row.payment.p_type === "check" ? "شيك" : "نقد"}
                        </div>
                        <div className="">{row.payment.p_code}</div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {moment(row.created_at.toString()).format("L")}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {row.city}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo">
                          {row.zone && row.zone.toUpperCase()}
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div className="font-cairo bg-mygreen py-1 px-2 font-bold text-white">
                          {row.amount} درهم
                        </div>
                      </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
