import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopNavigation from "../components/TopNavigation";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { endpoint } from "../env";

export default function AuditInsight() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const [list, setList] = useState([]);
  const data = JSON.parse(localstorage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading]=useState(true)
  const [response, setResponse]=useState(false)
  const [insights, setInsights] = useState({
    totale: 0,
  });

  const [allFiles, setAllFiles]=useState([])
  const [processedFiles, setProcessedFiles]=useState([])
  const [pendingFiles, setPendingFiles]=useState([])

  const [totalFiles, setTotalFiles]= useState()
  const [statePendingFiles, setStatePendingFiles]=useState({total:0, stamp_a:0, stamp_b:0, stamp_c:0})
  const [stateProcessedFiles, setStateProcessedFiles]=useState({total:0, stamp_a:0, stamp_b:0, stamp_c:0})
  
  function ElementHeader(){
    return (
    <div className="flex justify-items-start pt-2">
      <div className="ml-4 w-20">مدينة</div>
      <div className="ml-4 w-48">محكمة</div>
      <div className="ml-4 w-32">مرجع الملف</div>
      <div className="ml-4 w-20">القضية</div>
      <div className="ml-4 w-20">الدمغة</div>
      <div className="ml-4 w-20">المبلغ</div>
      <div className="ml-4 w-40">تاريخ العملية</div>
      <div className="ml-4 w-40">آخر اجل الأداء</div>
      <div className="ml-4 w-40">حالة الملف</div>
    </div>)
  }

  function ElementCard(props) {
    return props.operation.map((element) => {
      return (
        <div className="flex justify-items-start pt-2">
          <div className="ml-4 w-20">{element.city}
          </div>
          <div className="ml-4 w-48">{element.court}</div>
          <div className="ml-4 w-32">
            {element.file.number}/{element.file.code}/{element.file.year}
          </div>
          <div className="ml-4 w-20">{element.case_type?element.case_type.name:""}</div>
          <div className="ml-4 w-20">{element.stamp.name}</div>
          <div className="ml-4 w-20">{element.amount} درهم</div>
          <div className="ml-4 w-40">
          {moment(element.created_at.toString()).format("DD/MM/YY")}
          </div>
          <div className="ml-4 w-40">
          {moment(element.dueDate_at.toString()).format("DD/MM/YY")}
          </div>
          {element.state==="pending" && <div className="ml-4 w-40">
          {moment(element.dueDate_at).diff(moment(Date.now()),"days")>=0 ?<div className="ml-4 w-40 text-gray-400">إنتظار التصحيح</div>:<div className="ml-4 w-40 text-red-400">إحالة على الإقتطاع</div>}
          </div>}
          {element.state=="paid_original" && <div className="ml-4 w-20 text-blue-600">تم الأداء</div>}
          {element.state=="mistake" && <div className="ml-4 w-20 text-green-600">تم التصحيح</div>}
        </div>
      );
    });
  }

  function Card() {
    if (!mystate.audit.length) {
      mystate.audit = [];
    }
    return mystate.audit.map((element) => {
      return (
        <div className="bg-gray-200  p-4  text-black mb-12 overflow-auto font-semibold space-y-2">
          <div className="flex justify-items-start mb-8">
          <div className="flex ml-2 text-xl">( {element.lawyer.code} )</div>
            <div className="flex w-[300px] text-xl">{element.lawyer.name}</div>
            <div className="flex justify-between">
                المبلغ الاجمالي : {element.total_amount} درهم
            </div>
          </div>
          
          <ElementHeader/>
          <div className="border-b-2 border-black" />
          <ElementCard operation={element.operation} />
        </div>
      );
    });
  }

  async function calcTotal(data) {
    let temp_totalPendingFiles=[]
    let temp_totalProcessedFiles=[]
    let temp_processedFiles=[]
    let temp_pendingFiles=[]
    let total_pendingFiles=0
    
    let PEF_total_amount = 0;
    let PEF_stamp_a = 0;
    let PEF_stamp_b = 0;
    let PEF_stamp_c = 0;

    let PF_total_amount = 0;
    let PF_stamp_a = 0;
    let PF_stamp_b = 0;
    let PF_stamp_c = 0;

   
    await data.forEach((element) => {
      element.operation.forEach((e) => {
        if (e.state==="pending") {
          temp_pendingFiles.push(e)
          PEF_total_amount = PEF_total_amount + e.amount;
          if (e.stamp.name === "تلبس") PEF_stamp_a++;
          else if (e.stamp.name === "تذييل") PEF_stamp_b++;
          else if (e.stamp.name === "50 عادية") PEF_stamp_c++;
        }
        else if((e.state==="paid_original")) {
          temp_processedFiles.push(e)
          PF_total_amount = PF_total_amount + e.amount;
          if (e.stamp.name === "تلبس") PF_stamp_a++;
          else if (e.stamp.name === "تذييل") PF_stamp_b++;
          else if (e.stamp.name === "50 عادية") PF_stamp_c++;
        }
        });
        setPendingFiles(temp_pendingFiles)
        setProcessedFiles(temp_processedFiles)
        setStatePendingFiles({total:PEF_total_amount, stamp_a:PEF_stamp_a, stamp_b:PEF_stamp_b, stamp_c:PEF_stamp_c})
        setStateProcessedFiles({total:PF_total_amount, stamp_a:PF_stamp_a, stamp_b:PF_stamp_b, stamp_c:PF_stamp_c})
    });
 }

  useEffect(() => {
    document.documentElement.dir = "rtl";
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    if (!data) {
      return navigate("/login");
    }
    
    axios.get(endpoint + "/insight/audit_operation", {
        headers: { "x-token": data.user.token },
      })
      .then(function (response) {
        dispatch({ type: "setAudit", payload: response.data.payload.audit });
        calcTotal(response.data.payload.audit);
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        return console.log("error");
      });

    if (!mystate.user._id) {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (!data) {
        return navigate("/login");
      }
      axios
        .get(endpoint + "/insight/audit_operation", {
          headers: { "x-token": data.user.token },
        })
        .then(function (response) {
          dispatch({ type: "setAudit", payload: response.data.payload.audit });
          calcTotal(response.data.payload.audit);
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          return console.log(error);
        });
    }
    window.scrollTo(0, 0);
  }, [mystate.user._id]);

  return (
    <div className="container mx-auto mt-8 font-cairo  noselect">
      <TopNavigation user={data.user} />
      <div className=" text-2xl mb-12 font-semibold font-cairo mt-16">
        ملفات بدون دمغة
      </div>
      {!loading &&
      <div className="lg:flex">

        <div className="bg-black lg:w-[400px] p-4  text-white mb-12 font-semibold space-y-2">
          <div className="text-2xl mb-4">جميع الملفات المراقبة</div>
          <div className="flex justify-items-start justify-between text-xl mb-4">
            <div className="lg:w-[200px]">المجموع الاجمالي</div>
            <div>{statePendingFiles.total+stateProcessedFiles.total} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between  text-lg">
            <div className="lg:w-[200px]">تلبس</div>
            <div className="lg:w-[40px]">({statePendingFiles.stamp_a+stateProcessedFiles.stamp_a})</div>
            <div>{(statePendingFiles.stamp_a+stateProcessedFiles.stamp_a) * 500} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between text-lg">
            <div className="lg:w-[200px]">تذييل</div>
            <div className="lg:w-[40px]">({statePendingFiles.stamp_b+stateProcessedFiles.stamp_b})</div>
            <div>{(statePendingFiles.stamp_b+stateProcessedFiles.stamp_b) * 500} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between text-lg">
            <div className="lg:w-[200px]">50 عادية</div>
            <div className="lg:w-[40px]">({statePendingFiles.stamp_c+stateProcessedFiles.stamp_c})</div>
            <div>{(+stateProcessedFiles.stamp_c+statePendingFiles.stamp_c) * 50} درهم</div>
          </div>
        </div>

        <div className="bg-red-400 lg:mr-4 lg:w-[400px] p-4  text-white mb-12 font-semibold space-y-2">
          <div className="text-2xl mb-4">ملفات في إنتظار التصحيح</div>
          <div className="flex justify-items-start justify-between text-xl mb-4">
            <div className="lg:w-[200px]">المجموع الاجمالي</div>
            <div>{statePendingFiles.total} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between  text-lg">
            <div className="lg:w-[200px]">تلبس</div>
            <div className="lg:w-[40px]">({statePendingFiles.stamp_a})</div>
            <div>{statePendingFiles.stamp_a * 500} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between text-lg">
            <div className="lg:w-[200px]">تذييل</div>
            <div className="lg:w-[40px]">({statePendingFiles.stamp_b})</div>
            <div>{statePendingFiles.stamp_b * 500} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between text-lg">
            <div className="lg:w-[200px]">50 عادية</div>
            <div className="lg:w-[40px]">({statePendingFiles.stamp_c})</div>
            <div>{statePendingFiles.stamp_c * 50} درهم</div>
          </div>
        </div>
      
        <div className="bg-myblue lg:mr-4 lg:w-[400px] p-4  text-white mb-12 font-semibold space-y-2">
          <div className="text-2xl mb-4">ملفات بعد التسوية</div>
          <div className="flex justify-items-start justify-between text-xl mb-4">
            <div className="lg:w-[200px]">المجموع الاجمالي</div>
            <div>{stateProcessedFiles.total} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between  text-lg">
            <div className="lg:w-[200px]">تلبس</div>
            <div className="lg:w-[40px]">({stateProcessedFiles.stamp_a})</div>
            <div>{stateProcessedFiles.stamp_a * 500} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between text-lg">
            <div className="lg:w-[200px]">تذييل</div>
            <div className="lg:w-[40px]">({stateProcessedFiles.stamp_b})</div>
            <div>{stateProcessedFiles.stamp_b * 500} درهم</div>
          </div>
          <div className="flex justify-items-start justify-between text-lg">
            <div className="lg:w-[200px]">50 عادية</div>
            <div className="lg:w-[40px]">({stateProcessedFiles.stamp_c})</div>
            <div>{stateProcessedFiles.stamp_c * 50} درهم</div>
          </div>
        </div>

      </div>
      }
      {loading &&
      <div className="lg:flex">
      <div className="bg-black lg:w-[400px] p-4  text-white mb-12 font-semibold space-y-2 cursor-wait">
        <div className="text-2xl mb-4">ملفات في إنتظار التصحيح</div>
        <div className="flex justify-items-start text-xl mb-4">
          <div className="w-[200px]">المجموع الاجمالي</div>
          <div>xxxx درهم</div>
        </div>
        <div className="flex justify-items-start  text-lg">
          <div className="w-[200px]">تلبس</div>
          <div className="w-[40px]">xx</div>
          <div>xxxx درهم</div>
        </div>
        <div className="flex justify-items-start  text-lg">
          <div className="w-[200px]">تذييل</div>
          <div className="w-[40px]">xx</div>
          <div>xxxx درهم</div>
        </div>
        <div className="flex justify-items-start  text-lg">
          <div className="w-[200px]">50 عادية</div>
          <div className="w-[40px]">xx</div>
          <div>xxxx درهم</div>
        </div>
      </div>

      <div className="bg-red-400 lg:mr-4 lg:w-[400px] p-4  text-white mb-12 font-semibold space-y-2 cursor-wait">
      <div className="text-2xl mb-4">ملفات في إنتظار التصحيح</div>
      <div className="flex justify-items-start text-xl mb-4">
        <div className="w-[200px]">المجموع الاجمالي</div>
        <div>xxxx درهم</div>
      </div>
      <div className="flex justify-items-start  text-lg">
        <div className="w-[200px]">تلبس</div>
        <div className="w-[40px]">xx</div>
        <div>xxxx درهم</div>
      </div>
      <div className="flex justify-items-start  text-lg">
        <div className="w-[200px]">تذييل</div>
        <div className="w-[40px]">xx</div>
        <div>xxxx درهم</div>
      </div>
      <div className="flex justify-items-start  text-lg">
        <div className="w-[200px]">50 عادية</div>
        <div className="w-[40px]">xx</div>
        <div>xxxx درهم</div>
      </div>
      </div>

      <div className="bg-myblue lg:mr-4 lg:w-[400px] p-4  text-white mb-12 font-semibold space-y-2 cursor-wait">
      <div className="text-2xl mb-4">ملفات في إنتظار التصحيح</div>
      <div className="flex justify-items-start text-xl mb-4">
        <div className="w-[200px]">المجموع الاجمالي</div>
        <div>xxxx درهم</div>
      </div>
      <div className="flex justify-items-start  text-lg">
        <div className="w-[200px]">تلبس</div>
        <div className="w-[40px]">xx</div>
        <div>xxxx درهم</div>
      </div>
      <div className="flex justify-items-start  text-lg">
        <div className="w-[200px]">تذييل</div>
        <div className="w-[40px]">xx</div>
        <div>xxxx درهم</div>
      </div>
      <div className="flex justify-items-start  text-lg">
        <div className="w-[200px]">50 عادية</div>
        <div className="w-[40px]">xx</div>
        <div>xxxx درهم</div>
      </div>
      </div>

      </div>
      
      }

      <Card />
    </div>
  );
}
