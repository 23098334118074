// React imports
import { useDispatch, useSelector } from "react-redux"

// Icons imports
import closeIcon from "../assets/close.svg"

// External components
import axios from "axios"

// Internal components
import { endpoint } from "../env";

export default function NotFoundModal(props){
    function handleCancel(){
        props.onHide()
    }

    if(!props.show) return <></>
    else return (
        <div className="grid place-content-center font-cairo">
            <div className=" bg-white flex flex-col border-2 border-black h-[454px] w-[650px] rounded-[22px] px-12 py-[47px] relative">
                <div onClick={()=>handleCancel()} className="absolute top-[28px] right-[30px] cursor-pointer bg-[#262626] hover:bg-gray-400 h-[28px] w-[28px] grid place-content-center rounded-full">
                    <img src={closeIcon} className="h-[10px] w-[10px]" alt="close_icon"/>
                </div>
                <div className="flex flex-col grow">
                    <div className="flex justify-between mx-2">
                        <div className="flex flex-col mt-12 space-y-4">
                            <div className="text-[20px] font-regular font-IBM-Plex-Sans">الملف غير متوفر للتصحيح !</div>
                        </div>
                       
                    </div>
                    <div className="border-gray-400 mt-2 border-b-2"/>
                </div>
                <div className="pt-8 flex lg:space-x-8 justify-center space-x-2 flex-row-reverse">
                    <div
                        onClick={() => handleCancel("/")}
                        className="bg-gray-800 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
                    >
                        عودة
                    </div>
                </div>
            </div>
        </div>
       )
}