import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../env";
import TopNavigation from "../components/TopNavigation";
import CorrectMistakeModal from "../components/CorrectMistakeModal";
import NotFoundModal from "../components/NotFoundModal"
import Modal from 'react-bootstrap/Modal'

function MyModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      centered
      backdrop="static"
    >
      <Modal.Body
          as={CorrectMistakeModal}
          user={props.user}
          setOperationDone={()=>props.setOperationDone()}
          dataSubmit={props.dataSubmit}
          codeAvocat={props.codeAvocat}
          show={props.show}
          onHide={() => props.onHide()}/>
    </Modal>
  );
}
function MyModalError(props) {
  return (
    <Modal
      {...props}
      size="sm"
      centered
    >
      <Modal.Body
          as={NotFoundModal}
          user={props.user}
          setOperationDone={()=>props.setOperationDone()}
          dataSubmit={props.dataSubmit}
          codeAvocat={props.codeAvocat}
          show={props.show}
          onHide={() => props.onHide()}/>
    </Modal>
  );
}
export default function CorrectionMistake() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const [fileData, setFileData]= useState()
  const [modalVisible, setModalVisible]= useState(false)
  const [modalVisibleError, setModalVisibleError]= useState(false)
  const [operationDone, setOperationDone] = useState(false);
  const [fileFound, setFileFound] = useState(false);
  const [amount, setAmount]= useState(0)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading]= useState(true)

  const [option, SetOption] = useState({
    option_a: false,
    option_b: false,
    option_c: false,
  });

  const [case_type,setCase_type]= useState({
    type_a: false,
    type_b: false,
    type_c: false,
  });
  const [case_type_selected,setCase_type_selected]=useState({code:"", name:""})
  const [codeAvocat, setCodeAvocat] = useState("");
  const [selectedCourt, setSelectedCourt] = useState("اختر المحكمة");
  const courtList= [
    "إستئنافية الناظور",
    "ابتدائية الناظور",
    "اسرة",
    "إستئنافية الحسييمة",
    "ابتدائية الحسييمة",
    "ابتدائية الدريوش",
    "التجارية",
    "الإدارية",
  ]
  const [year, setYear] = useState("");
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");

  const [is_listopen, setIs_listopen] = useState(false);

  function CourtList() {
    return courtList.map((element) => (
      <div
        className={
          element === selectedCourt
            ? "mb-1 cursor-pointer bg-indigo-300 hover:bg-blue-500 hover:text-white rounded-sm px-2"
            : "mb-1 cursor-pointer  hover:bg-blue-500 hover:text-white rounded-sm px-2"
        }
        onClick={() => {
          setSelectedCourt(element);
          setIs_listopen(!is_listopen);
        }}
      >
        {element}
      </div>
    ));
  }

  const [dataResponse, setDataResponse] = useState({});
  function resetValue() {
    setCodeAvocat("");
    SetOption({
      option_a: true,
      option_b: false,
      option_c: false,
    });
    setSelectedCourt("اختر المحكمة");
    setYear("");
    setCode("");
    setNumber("");
  }

  function submit() {
    if(case_type.name==="" || codeAvocat==="" || year==="" || code==="" || number==="")
    return console.log("Please make sure to add all informations")
    
    let dataSubmit = {
      court: selectedCourt,
      file: {
        year: year,
        code: code,
        number: number,
      },
      case_type:{
        code:case_type_selected.code,
        name:case_type_selected.name
      },
    };
    axios.post(endpoint + "/user/correct_mistake/" + codeAvocat, dataSubmit, {headers: { "x-token": data.user.token },})
    .then(function (response)
      {
        
        
      }).catch((error) => {
      return console.log(error);
    });
   
  }

  function search() {
    if(case_type.name==="" || codeAvocat==="" || year==="" || code==="" || number==="")
    return console.log("Please make sure to add all informations")
    
    let dataSubmit = {
      court: selectedCourt,
      file: {
        year: year,
        code: code,
        number: number,
      },
      case_type:{
        code:case_type_selected.code,
        name:case_type_selected.name
      },
    };
    axios.post(endpoint + "/user/search_to_correct/" + codeAvocat, dataSubmit, {headers: { "x-token": data.user.token },})
    .then(function (response)
      {
        if(response.data.payload.operation.amount)
        {
          dataSubmit.amount=response.data.payload.operation.amount
          setFileData(dataSubmit)
          setAmount(response.data.payload.operation.amount)
          setModalVisible(true)
        }
        else setModalVisibleError(true)
      }).catch((error) => {
      return console.log(error);
    });
   
  }

  useEffect(() => {
    document.documentElement.dir = "rtl";
    window.scrollTo(0, 0);
    function loadLocalStorage() {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (data) {
        if(data.user) {
          dispatch({type:"user/SET",payload:data.lawyer})
          setLoading(false)
        }
        else if(data.lawyer){
          setLoading(false)
          return navigate("/home_lawyer")
        }
      }
      else if (!data) {
        setLoading(false)
        return navigate("/login")
      }
    }

  if(loading) loadLocalStorage()

  },[loading]);

  if(!loading)return (
    <div className="flex flex-col  container lg:mx-auto mt-8   lg:w-[800px] px-4 font-cairo">
      <MyModal
        dataSubmit={fileData}
        setOperationDone={()=>setOperationDone(true)}
        codeAvocat={codeAvocat}
        user={data.user}
        show={modalVisible}
        onHide={() => setModalVisible(false)}
      />
      <MyModalError
        dataSubmit={fileData}
        codeAvocat={codeAvocat}
        user={data.user}
        show={modalVisibleError}
        onHide={() => setModalVisibleError(false)}
      />
      <TopNavigation user={data.user} />
      <div className="text-2xl font-semibold mt-16">التصحيح بتدارك الخطأ</div>
      {!operationDone && (
        <div className="flex flex-col space-y-6 mt-12 noselect">
          <div className="space-y-4">
            <div className="text-lg font-cairo">المكتب</div>
            <input
              type="text"
              className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 "
              onChange={(e) => setCodeAvocat(e.target.value)}
              value={codeAvocat}
            />
          </div>
          <div className="relative">
            <div className="text-lg font-cairo mb-4">المحكمة</div>
            <div
              onClick={() => setIs_listopen(!is_listopen)}
              type="text"
              className="bg-white border-2 border-gray-200  rounded-md h-12 flex px-2 text-center items-center text-lg w-60 cursor-pointer"
            >
              {selectedCourt}
            </div>
            {is_listopen && (
              <div className="bg-mygray border-2 border-gray-200 shadow-md w-60 py-2 px-2 rounded-lg absolute">
                <CourtList />
              </div>
            )}
          </div>
          <div className="space-y-4">
            <div className="text-lg font-cairo">مرجع الملف</div>
            <div className="flex justify-between w-[340px] mt-2">
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-20 "
                onChange={(e) => setYear(e.target.value)}
                value={year}
                placeholder="السنة"
              />
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-36 "
                onChange={(e) => setCode(e.target.value)}
                value={code}
                placeholder="الرمز" 
              />
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-20 "
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                placeholder="الرقم"
              />
            </div>
          </div>
          <div className="font-cairo space-y-4">
            <div className="text-lg">نوع القضية</div>
            <div className="flex justify-between lg:w-[360px] flex-row-reverse">
              <div
                type="text"
                className={`rtl border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ${
                  case_type.type_a ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  setCase_type({
                    type_a: true,
                    type_b: false,
                    type_c: false,
                  });
                  setCase_type_selected({ code: 1, name: "شكاية" });
                }}
              >
                 شكاية
              </div>
              <div
                type="text"
                className={`border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ${
                  case_type.type_b ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  setCase_type({
                    type_a: false,
                    type_b: true,
                    type_c: false,
                  });
                  setCase_type_selected({ code: 2, name: "مدني" });
                }}
              >
                مدني
              </div>
              <div
                type="text"
                className={`border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer  ${
                  case_type.type_c ? "bg-red-400 text-white font-bold" : ""
                }`}
                onClick={() => {
                  setCase_type({
                    type_a: false,
                    type_b: false,
                    type_c: true,
                  });
                  setCase_type_selected({ code: 3, name: "جنحي" });
                }}
              >
                جنحي
              </div>
            </div>
            
          </div>
          {fileFound && <div className="font-cairo space-y-4">
            <div className="text-lg bg-gr">مبلغ التصحيح</div>
            <div className="flex justify-between lg:w-[360px]">
              <div
                type="text"
                className="rtl border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center">
                 {amount}
              </div>
            </div>
            
          </div>}
          
          <div className="pt-8 flex lg:space-x-8 space-x-2 justify-end flex-row-reverse">
              <div
                onClick={() => navigate("/")}
                className="bg-gray-800 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
              >
                إلغاء
              </div>
              {!fileFound &&<div
                className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
                onClick={() => search()}
              >
                بحث
              </div>}
              {fileFound && <div
                className="bg-green-600 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer"
                onClick={() => submit()}
              >
                تصحيح
              </div>}
          </div>
        </div>
      )}
      {operationDone && (
        <div className="mt-12 ">
          <div className="text-xl">تمت عملية التصحيح بتدارك الخطأ بنجاح</div>
          <div className="flex flex-col mt-8 space-y-4 text-lg bg-green-400 py-8 px-4 rounded-lg mb-4 lg:px-12">
            <div className="flex justify-between">
              <div classname=" font-semibold">المحكمة</div>
              <div classname=" font-semibold">{fileData.court}</div>
            </div>
            <div className="flex justify-between">
              <div classname=" font-semibold">مرجع الملف</div>
              <div classname=" font-semibold">
                {fileData.file.number}/{fileData.file.code}/
                {fileData.file.year}
              </div>
            </div>
            <div className="flex justify-between">
              <div classname=" font-semibold">نوع القضية</div>
              <div classname=" font-semibold">
              {fileData.case_type.name}
              </div>
            </div>
          </div>
          <div
            className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
            onClick={() => {
              resetValue()
              setOperationDone(false);
              navigate("/correction_mistake");
            }}
          >
            تابع ايضا
          </div>
        </div>
      )}
    </div>
  );
}
