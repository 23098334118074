import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import show_icon from "../assets/viewer.png";
import hide_icon from "../assets/hidden.png";
import { useNavigate } from "react-router-dom";
import { endpoint } from "../env";

export default function Login() {
  console.log(endpoint);
  const [passwordShown, setPasswordShown] = useState(false);
  const [inputs, setInputs] = useState({ phone: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [waitingResponse, setWaitingResponse]=useState(false)
  const navigate = useNavigate();

  const mystate = useSelector((state) => state);
  const dispatch = useDispatch();

  function tooglePassword() {
    setPasswordShown(!passwordShown);
  }

  function login() {
    setWaitingResponse(true);
    if (inputs.phone && inputs.password) {
      axios
        .post(endpoint + "/auth/login", inputs)
        .then(function (response) {
          if (response.status === 200) {
            setWaitingResponse(false);
            localStorage.setItem(
              "damghaState",
              JSON.stringify(response.data.payload)
            );
            return navigate("/");
          }
        })
        .catch((error) => {
          setError(true)
          setWaitingResponse(false);
        });
    } else {
      setWaitingResponse(false);
    }
  }

  useEffect(() => {
    document.documentElement.dir = "ltr";
    function loadLocalStorage() {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (data) {
        setLoading(false)
        return navigate("/");
      }
      if(!data)setLoading(false)
    }

    loadLocalStorage();
  }, [loading]);

  return (
    <div className="mx-8 lg:mx-auto font-Saira lg:w-[600px] flex flex-col h-screen">
      <div className="mt-20 flex text-3xl font-semibold text-dark">
        Administration : Accéder à Damgha Cloud
      </div>

      <div className="flex flex-col grow space-y-4 mt-20 pt-12 relative">
      {error &&
          <div className="top-4 flex text-lg text-red-400 font-semibold  absolute">
            *Informations de connexion invalides
          </div>
        }
        <div className="flex flex-col">
          <div className="text-xs font-semibold mb-2 text-gray-600">
            Numéro de téléphone
          </div>
          <input
            type="text"
            className="w-full bg-gray-50 font-semibold h-12 rounded-lg px-4 focus:outline-none "
            placeholder="Insérez numéro de téléphone"
            onChange={(e) =>
              setInputs({
                phone: e.target.value,
                password: inputs.password,
              })
            }
            value={inputs.phone}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-xs font-semibold mb-2 text-gray-600">
            Mot de passe
          </div>
          <div className="flex items-center relative mb-16">
            <input
              type={passwordShown ? "text" : "password"}
              className="w-full bg-gray-50 font-semibold h-12 rounded-lg px-4 focus:outline-none"
              placeholder="Mot de passe"
              onChange={(e) =>
                setInputs({ phone: inputs.phone, password: e.target.value })
              }
              value={inputs.password}
            />
            <img
              src={passwordShown ? show_icon : hide_icon}
              className="h-6 right-4  cursor-pointer  absolute"
              onClick={tooglePassword}
            />
          </div>
        </div>
        {!waitingResponse &&
          <div
            className={
              loading
                ? "w-full mt-14 h-12 bg-black rounded-md text-white flex items-center justify-center font-semibold opacity-60"
                : "w-full mt-14 h-12 bg-black rounded-md text-white font-semibold flex items-center justify-center cursor-pointer"
            }
            onClick={() => login()}
          >
            CONNEXION
          </div>
        }
        {waitingResponse &&
          <div
            className="w-full mt-14 h-12 bg-black rounded-md text-white flex items-center justify-center font-semibold opacity-60"
            onClick={() => login()}
          >
            CONNEXION EN COURS...
          </div>
        }
      </div>
    </div>
  );
}
