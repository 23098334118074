const searchReducer = (state = {}, { type, payload }) => {
    switch (type) {
      case "search/SET_RESULT":
        return payload;
      default:
        return state;
    }
  };
  
  export default searchReducer;
  