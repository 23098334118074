import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../../env";
import TopNavigation from "./TopNavigation";
import * as moment from "moment";

export default function PenalityLawyer() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const [fileCount, setFileCount]= useState(0)


  function ElementHeader() {
    return (
      <div className="flex justify-items-start pt-2">
        <div className="ml-4 w-20">مدينة</div>
        <div className="ml-4 w-48">محكمة</div>
        <div className="ml-4 w-32">مرجع الملف</div>
        <div className="ml-4 w-20">القضية</div>
        <div className="ml-4 w-20">الدمغة</div>
        <div className="ml-4 w-20">المبلغ</div>
        <div className="ml-4 w-40">تاريخ العملية</div>
        <div className="ml-4 w-40">آخر اجل الأداء</div>
        <div className="ml-4 w-40">حالة الملف</div>
      </div>
    );
  }
  function ElementCard(props) {
    return props.operation.map((element) => {
      if(element.state==="pending") return (
        <div key={element._id} className="flex justify-items-start pt-2">
          <div className="ml-4 w-20">
            {element.city}
          </div>
          <div className="ml-4 w-48">{element.court}</div>
          <div className="ml-4 w-32">
            {element.file.number}/{element.file.code}/{element.file.year}
          </div>
          <div className="ml-4 w-20">
            {element.case_type ? element.case_type.name : ""}
          </div>
          <div className="ml-4 w-20">{element.stamp.name}</div>
          <div className="ml-4 w-20">{element.amount} درهم</div>
          <div className="ml-4 w-40">
            {moment(element.created_at).format("DD/MM/Y")}
          </div>
          <div className="ml-4 w-40">
          {moment(element.dueDate_at).format("DD/MM/Y")}
          </div>
          <div className="ml-4 w-40">
          {moment(element.dueDate_at).diff(moment(Date.now()),"days")>=0 ?<div className="ml-4 w-40 text-gray-400">إنتظار التصحيح</div>:<div className="ml-4 w-40 text-red-400">إحالة على الإقتطاع</div>}
          </div>
        </div>
      );
    });
  }
  function Card() {
    if (!mystate.search.audit || !mystate.search.audit.operation) {
      mystate.search.audit = { operation: [] };
    }
    return (
      <div className="bg-gray-200 overflow-auto  p-4  text-black mb-12 font-semibold space-y-2">
        <ElementHeader />
        <div className="border-b-2 border-black" />
        <ElementCard operation={mystate.search.audit.operation} />
      </div>
    );
  }

  useEffect(() => {
    function submit(data) {
      axios
        .get(endpoint + "/insight/audit_operation/" + data.lawyer.code, {
          headers: { "x-token": data.lawyer.token },
        })
        .then(function (response) {
          dispatch({
            type: "search/SET_RESULT",
            payload: response.data.payload,
          });
          let audit = response.data.payload.audit;
          let total_amount = 0;
          let count=0
          audit.operation.forEach((element) => {
            console.log(element)
            if(element.state==="pending"){
              total_amount = total_amount + element.amount
              count=count+1
            }
          });
          setTotalAmount(total_amount);
          setFileCount(count)
        })
        .catch((error) => {
          return console.log("error");
        });
    }
    document.documentElement.dir = "rtl";
    const localstorage = localStorage.getItem("damghaState");
    const data = JSON.parse(localstorage);
    if (data) {
      dispatch({ type: "lawyer/SET", payload: data.lawyer });
      submit(data);
    }

    if (!data) {
      return navigate("/login");
    }

    window.scrollTo(0, 0);
  }, [mystate.lawyer._id]);

  return (
    <div className="container mx-auto mt-8 noselect font-cairo mb-12">
      <TopNavigation lawyer={mystate.lawyer} />
      <div className="border-b-2 border-gray-200 mt-12 w-full" />

      {mystate.search.lawyer && (
        <div className="text-2xl mb-12 font-semibold mt-16">
          محضر إشعار بملفات دون دمغة
        </div>
      )}
      {mystate.search.audit && mystate.search.audit.total_amount && (
        <div>
          <div className="bg-red-400 lg:w-[400px] mt-12 p-4  text-white mb-12 font-semibold space-y-2">
            <div className="flex justify-items-start text-xl mb-8">
              <div className="w-[200px]">ملفات بدون دمغة</div>
            </div>
            <div className="flex justify-items-start text-xl mb-6">
              <div className="w-[200px]">المجموع الاجمالي</div>
              <div>{totalAmount}- درهم</div>
            </div>
            <div className="flex justify-items-start  text-xl">
              <div className="w-[200px]">عدد الملفات</div>
              <div className="w-[40px]">
                {fileCount}
              </div>
            </div>
          </div>
          <Card />
        </div>
      )}
    </div>
  );
}
