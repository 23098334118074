import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function TopBar(props) {
  const [lawyer, setlawyer] = useState(props.lawyer);
  const navigate = useNavigate();
  function logout() {
    console.log("lading page")
    localStorage.removeItem("damghaState");
    return navigate("/");
  }
  return (
    <div className="flex justify-between">
      <Helmet>
        <title>مشروع الدمغة</title>
      </Helmet>
      <div>{lawyer.name}</div>
      <div onClick={() => logout()} className="cursor-pointer">
        تسجيل خروج
      </div>
    </div>
  );
}
