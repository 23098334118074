import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { endpoint } from "../env";
import TopNavigation from "../components/TopNavigation";


export default function Sell() {
  const mystate = useSelector((state) => state);
  const localstorage = localStorage.getItem("damghaState");
  const data = JSON.parse(localstorage);
  const [operationDone, setOperationDone] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [option, SetOption] = useState({
    optionOne: true,
    optionTwo: false,
    value: 50,
  });
  const [optionPay, SetOptionPay] = useState({
    optionM: true,
    optionC: false,
  });
  const [paymentCode, setPaymentCode] = useState("");
  const [codeAvocat, setCodeAvocat] = useState();
  const [quantity, setQuantity] = useState(1);
  const [totalAmount, setTotalAmount] = useState(50);
  const [dataResponse, setDataResponse] = useState({});
  const [stamp_serie, setStamp_serie] = useState({ from: "", to: "" });
  function resetValue() {
    setPaymentCode("");
    setCodeAvocat();
    setQuantity(1);
    setTotalAmount(50);
    SetOptionPay({
      optionM: true,
      optionC: false,
    });
    SetOption({
      optionOne: true,
      optionTwo: false,
      value: 50,
    });
  }
  function logout() {
    localStorage.removeItem("damghaState");
    return navigate("/login");
  }
  function submit() {
    let paymentdata = optionPay.optionM
      ? { p_type: "cash", p_code: null }
      : { p_type: "check", p_code: paymentCode };
    let dataSubmit = {
      codeAvocat: codeAvocat,
      item_name: option.value,
      item_price: option.value,
      stamp_serie: { from: stamp_serie.from, to: stamp_serie.to },
      payment_type: paymentdata.p_type,
      payment_code: paymentdata.p_code,
      quantity: quantity,
      amount: totalAmount,
    };
    axios.post(endpoint + "/user/sell/" + codeAvocat, dataSubmit, {
        headers: { "x-token": data.user.token },
      })
      .then(function (response) {
        setDataResponse(response.data.payload);
        console.log(dataSubmit);
        resetValue();
        setOperationDone(true);
      })
      .catch((error) => {
        return console.log(error);
      });

    navigate("/sell");
  }

  useEffect(() => {
    document.documentElement.dir = "rtl";
    if (mystate.user._id) {
      const localstorage = localStorage.getItem("damghaState");

      const data = JSON.parse(localstorage);
      if (!data) {
        return navigate("/login");
      }
      axios
        .get(endpoint + "/user", {
          headers: { "x-token": data.user.token },
        })
        .then(function (response) {
          dispatch({ type: "setUser", payload: response.data.payload.user });
        })
        .catch((error) => {
          return console.log("error");
        });
    } else if (!mystate.user._id) {
      const localstorage = localStorage.getItem("damghaState");
      const data = JSON.parse(localstorage);
      if (!data) {
        return navigate("/login");
      }
      axios
        .get(endpoint + "/user", {
          headers: { "x-token": data.user.token },
        })
        .then(function (response) {
          dispatch({ type: "setUser", payload: response.data.payload.user });
        })
        .catch((error) => {
          return console.log(error);
        });
    }
    window.scrollTo(0, 0);
  }, [mystate.user._id]);

  return (
    <div className="flex flex-col  container lg:mx-auto mt-8   lg:w-[800px] px-4 font-cairo noselect ">
      <TopNavigation user={data.user} />
      {!operationDone && (
        <div className="flex flex-col space-y-4 mt-20">
          <div className="">
            <div className="text-lg font-cairo mb-2">الرقم المهني</div>
            <input
              type="text"
              className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 "
              onChange={(e) => setCodeAvocat(e.target.value)}
              value={codeAvocat}
            />
          </div>
          <div className="font-cairo">
            <div className="text-lg">فئة الدمغة</div>
            <div className="flex lg:justify-between lg:w-[240px]">
              <div
                type="text"
                className={`border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ml-4 ${
                  option.optionOne
                    ? "bg-red-400 text-white font-bold"
                    : "bg-mygray opacity-70"
                }`}
                onClick={() =>
                  SetOption(
                    {
                      optionOne: true,
                      optionTwo: false,
                      value: 50,
                    },
                    setTotalAmount(50 * quantity)
                  )
                }
              >
                50 DH
              </div>
              <div
                type="text"
                className={`border-2 border-gray-200  rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer  ${
                  option.optionTwo
                    ? "bg-red-400 text-white font-bold"
                    : "bg-mygray opacity-70"
                }`}
                onClick={() =>
                  SetOption(
                    {
                      optionOne: false,
                      optionTwo: true,
                      value: 500,
                    },
                    setTotalAmount(500 * quantity)
                  )
                }
              >
                500 DH
              </div>
            </div>
          </div>
          <div className="flex lg:justify-between lg:w-[300px]">
            <div className="">
              <div className="text-lg font-cairo mb-2">وحدات</div>
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 ml-4"
                pattern="[0-9]*"
                onChange={(e) =>
                  e.target.validity.valid
                    ? setQuantity(
                        e.target.value,
                        setTotalAmount(option.value * e.target.value)
                      )
                    : null
                }
                value={quantity}
              />
            </div>
            <div className=" ">
              <div className="text-lg font-cairo mb-2">الواجب أدائه</div>
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200 rounded-md h-12 text-center text-lg w-40"
                disabled={true}
                value={totalAmount + " DH"}
              />
            </div>
          </div>
          <div className="">
            <div className="text-lg font-cairo">الرقم التسلسي للدمغة</div>
            <div className="flex lg:justify-between w-[300px] mt-2">
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-36 ml-4"
                onChange={(e) =>
                  setStamp_serie({ from: e.target.value, to: stamp_serie.to })
                }
                value={stamp_serie.from}
                placeholder="من"
              />
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200  rounded-md h-12 text-center text-lg w-36 "
                onChange={(e) =>
                  setStamp_serie({ to: e.target.value, from: stamp_serie.from })
                }
                value={stamp_serie.to}
                placeholder="الى"
              />
            </div>
          </div>
          <div className=" space-x-12">
            <div className="">
              <div className="text-lg font-cairo mb-2">طريقة الاداء</div>
              <div className="flex lg:justify-between lg:w-[240px]">
                <div
                  className={`border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ml-4 ${
                    optionPay.optionM
                      ? "bg-red-400 text-white font-bold"
                      : "bg-mygray opacity-70"
                  }`}
                  onClick={() =>
                    SetOptionPay({
                      optionM: true,
                      optionC: false,
                    })
                  }
                >
                  نقد
                </div>
                <div
                  className={`border-2 border-gray-200 rounded-md h-12 text-center text-lg w-28 flex justify-center items-center cursor-pointer ${
                    optionPay.optionC
                      ? "bg-red-400 text-white font-bold"
                      : "bg-mygray opacity-70"
                  }`}
                  onClick={() =>
                    SetOptionPay({
                      optionM: false,
                      optionC: true,
                    })
                  }
                >
                  شيك
                </div>
              </div>
            </div>
          </div>
          {optionPay.optionC && (
            <div className="">
              <div className="text-xl font-cairo">رقم الشيك</div>
              <input
                type="text"
                className="bg-mygray border-2 border-gray-200 mt-4 rounded-md h-12 text-center text-lg w-48 "
                onChange={(e) => setPaymentCode(e.target.value)}
                value={paymentCode}
              />
            </div>
          )}

          <div className="pt-8 flex lg:space-x-8 space-x-2 justify-end flex-row-reverse">
            <div className="bg-gray-800 text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer ">
              إلغاء
            </div>
            <div
              className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
              onClick={() => submit()}
            >
              حفظ
            </div>
          </div>
        </div>
      )}
      {operationDone && (
        <div className="mt-20 ">
          <div className="text-xl">تمت عملية التسجيل بنجاح</div>
          <div className="flex flex-col mt-8 space-y-4 text-lg bg-green-400 py-8 px-4 rounded-lg mb-4">
            <div classname=" font-semibold">
              الرقم المهني :{dataResponse.lawyer.code}
            </div>
            <div classname=" font-semibold">
              اسم المحامي : {dataResponse.lawyer.name}
            </div>
            <div classname=" font-semibold">
              فئة الدمغة : {dataResponse.sale.item.name}
            </div>
            <div classname=" font-semibold">
              وحدات : {dataResponse.sale.quantity}
            </div>
            <div classname=" font-semibold">
              الرقم التسلسي للدمغة :{dataResponse.sale.stamp_serie.from}/
              {dataResponse.sale.stamp_serie.to}
            </div>
            <div classname=" font-semibold">
              طريقة الاداء :
              {dataResponse.sale.payment.p_type === "check" ? "شيك" : "نقد"}
            </div>
            <div classname=" font-semibold">
              {dataResponse.sale.payment.p_code}
            </div>
            <div classname=" font-semibold">
              المبلغ الاجمالي : {dataResponse.sale.amount}
            </div>
          </div>
          <div
            className="bg-myblue text-white text-xl font-semibold lg:w-[180px] w-1/2 h-12 grid place-items-center rounded-md cursor-pointer "
            onClick={() => {
              setOperationDone(false);
              navigate("/sell");
            }}
          >
            تابع ايضا
          </div>
        </div>
      )}
    </div>
  );
}
